import { useState, useEffect } from "react"

export default function useInfoPanel(currEntity) {
  const [infoPanelOpen, setInfoPanelOpen] = useState(true)
  useEffect(() => {
    ;[].push(currEntity)
    setInfoPanelOpen(true)
  }, [currEntity])
  return [infoPanelOpen, setInfoPanelOpen]
}
