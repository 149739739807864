export function getDsPath(langId: string, election?: ElectionDef, dataset?: ElectionDataset) {
  if (!election) return `${langId}`
  return `${langId}/${election.country}/${election.type}/${election.year}/${
    dataset?.slug ?? ""
  }`
}

export function getElectionUid(e: ElectionDef) {
  return `${e.country}_${e.type}_${e.year}`
}

const RU_LEVELS = [
  {
    id: 0,
    name: "country",
    default: "Overall result",
    groupBy: "candidates",
  },
  {
    id: 1,
    name: "region",
    key: "region",
    groupBy: "candidates",
    uidKeys: ["region"],
  },
  {
    id: 2,
    name: "tik",
    key: "tik",
    groupBy: "candidates",
    uidKeys: ["region", "name"],
  },
  {
    id: 3,
    name: "station",
    key: "uik",
    groupBy: "candidates",
    prefix: "УИК №",
    uidKeys: ["region", "name"],
  },
]

interface ElectionDataset {
  id: number
  slug?: string
  name: string
  results: string | null
  totalStations?: number
  countingStatus?: number
  dateUpdated?: Date
  socialImages?: {
    de: string
    en: string
    ru: string
    uk: string
  }
}

interface ElectionDef {
  country: string
  type: string
  year: string
  hidden?: boolean
  groupBy: string
  colorBy?: string
  datasets: ElectionDataset[]
  title: {
    de: string
    en: string
    ru: string
    uk: string
  }
  candidates: string
  stations: string[]
  combined?: boolean
  levels?: {
    id: number
    name: string
    key?: string
    default?: string
    groupBy: string
    uidKeys?: string[]
    prefix?: string
  }[]
  presets?: boolean
  sourceLang: string
  sourceUrl: string
  sourceName: string
  customFilters?: {
    name: string
    condition: (entity) => boolean
    filter: (child) => boolean
  }[]
}

export const elections: ElectionDef[] = [
  {
    country: "germany",
    type: "parliamentary",
    year: "2021",
    hidden: true,
    groupBy: "candidates",
    colorBy: "candidates",
    datasets: [
      {
        id: 0,
        name: "party-list",
        results: null,
        countingStatus: 100,
        dateUpdated: new Date("26 Sep 2021 20:00:00 GMT+2"),
        socialImages: {
          de: "https://specials.dekoder.org/content/uploads/2021/09/btw_2021_de.png",
          en: "https://specials.dekoder.org/content/uploads/2021/09/btw_2021_en.png",
          ru: "https://specials.dekoder.org/content/uploads/2021/09/btw_2021_ru.png",
          uk: "https://specials.dekoder.org/content/uploads/2021/09/btw_2021_ru.png",
        },
      },
    ],
    levels: [
      {
        id: 0,
        name: "country",
        groupBy: "candidates",
      },
      {
        id: 1,
        name: "region",
        key: "region",
        groupBy: "candidates",
        uidKeys: ["region"],
      },
      {
        id: 2,
        name: "station",
        key: "name",
        groupBy: "candidates",
        uidKeys: ["region", "name"],
      }
    ],
    title: {
      de: "Bundestagswahl 2021",
      en: "Federal Elections 2021",
      ru: "Выборы в Бундестаг 2021",
      uk: "Вибори в Бундестаг 2021",
    },
    candidates: "/elections/2021-germany-parliamentary/candidates.json",
    stations: ["/elections/2021-germany-parliamentary/btw_test.csv"],
    combined: true,
    sourceLang: "de",
    sourceUrl: "https://www.bundeswahlleiter.de/bundestagswahlen/2021.html",
    sourceName: "bundeswahlleiter.de",
  },
  /* {
    country: "belarus",
    type: "presidential",
    year: "2020",
    groupBy: "candidates",
    colorBy: "candidates",
    datasets: [
      {
        id: 0,
        name: "1st round",
        countingStatus: 100,
        results: null, // "https://space.dekoder.org/elections/2020-russia-constitution/results/results_05_Jul_2020_16_23.csv",
        dateUpdated: new Date("22 Aug 2020 19:05:00 GMT+3"),
        socialImage:
          "https://specials.dekoder.org/content/uploads/2020/07/russia_const_ref_social.png",
      },
    ],
    title: {
      de: "Präsidentschaftswahl in Belarus 2020",
      en: "Presidential Election in Belaurus 2020",
      ru: "Выборы Президента Беларуси 2020",
      uk: "Выборы Президента Беларуси 2020",
    },
    candidates:
      "/elections/2020-belarus-presidential/candidates/candidates.json",
    stations: [
      "https://space.dekoder.org/elections/2020-belarus-presidential/by_merged_test.csv", // stations-2020   // cik_uik_28_06_2020_new2
    ],
    combined: true,
    levels: [
      {
        id: 0,
        name: "country",
        default: "Overall result",
        groupBy: "candidates",
      },
      {
        id: 1,
        name: "region",
        key: "region",
        groupBy: "candidates",
        uidKeys: ["region"],
      },
      {
        id: 2,
        name: "tik",
        key: "tik",
        groupBy: "candidates",
        uidKeys: ["region", "name"],
      },
      {
        id: 3,
        name: "station",
        key: "uik",
        groupBy: "candidates",
        prefix: "",
        uidKeys: ["code"],
      },
    ],
    presets: false,
    sourceLang: "ru",
    sourceUrl:
      "http://www.vybory.izbirkom.ru/region/region/izbirkom?action=show&root=1&tvd=100100163596969&vrn=100100163596966&region=0&global=1&sub_region=0&prver=0&pronetvd=null&vibid=100100163596969&type=465",
    sourceName: "izbirkom.ru",
  }, */

  /* 
    RU PRESIDENTIAL ELECTIONS 2024
  */
  {
    country: "russia",
    type: "presidential",
    year: "2024",
    groupBy: "candidates",
    colorBy: "turnout",
    datasets: [
      {
        id: 0,
        name: "1st round",
        results: null,
        countingStatus: 98,
        dateUpdated: new Date("20 Mar 2024 11:02:00 GMT+1"),
        socialImages: {
          de: "https://specials.dekoder.org/content/uploads/2024/03/russia_presidential_election_2024_social.jpg",
          en: "https://specials.dekoder.org/content/uploads/2024/03/russia_presidential_election_2024_social.jpg",
          ru: "https://specials.dekoder.org/content/uploads/2024/03/russia_presidential_election_2024_social.jpg",
          uk: "https://specials.dekoder.org/content/uploads/2024/03/russia_presidential_election_2024_social.jpg",
        }
      }
    ],
    title: {
      de: "Präsidentschaftswahl in Russland 2024",
      en: "Presidential Election in Russla 2024",
      ru: "Выборы Президента России 2024 года",
      uk: "Вибори Президента Росії 2024 року",
    },
    candidates: "/elections/2024-russia-presidential/candidates_2024.json",
    stations: [
      "/elections/2024-russia-presidential/ru_president_2024_1710953267.csv",
    ],
    combined: true,
    levels: RU_LEVELS,
    presets: false,
    sourceLang: "ru",
    sourceUrl:
      "http://www.vybory.izbirkom.ru/",
    sourceName: "izbirkom.ru",
  },

  /**
   * RUSSIA DUMA ELECTIONS 2021
   */

  {
    country: "russia",
    type: "parliamentary",
    year: "2021",
    groupBy: "candidates",
    colorBy: "candidates", // "turnout", //
    datasets: [
      {
        id: 0,
        name: "party-list",
        // countingStatus: 100,
        results: null, // "https://space.dekoder.org/elections/2020-russia-constitution/results/results_05_Jul_2020_16_23.csv",
        dateUpdated: new Date("29 Sep 2021 20:00:00 GMT+3"),
        socialImages: {
          de: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_de.png",
          en: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_en.png",
          ru: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_ru.png",
          uk: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_ru.png",
        },
      },
    ],
    title: {
      de: "Dumawahl 2021",
      en: "Duma Elections 2021",
      ru: "Выборы в Госдуму 2021 г.",
      uk: "Выборы в Госдуму 2021 г.",
    },
    candidates: "/elections/2021-russia-duma/parties_2021.json",
    // "/elections/2021-russia-duma/parties_2021.json",
    stations: [
      // "https://space.dekoder.org/elections/2021-russia-duma/duma_2021_direct_test.csv",
      "https://space.dekoder.org/elections/2021-russia-duma/duma_2021_1633085245.csv",
    ],
    combined: true,
    levels: RU_LEVELS,
    customFilters: [
      {
        name: "no electronic vote",
        condition: (entity) => entity.uidLong === "moscow_city",
        // condition: entity => entity.children.find(c => (c.level.id === 3 && c.name.match(/50[0-9]{2}$/))),
        filter: (child) =>
          !(child.level.id === 3 && child.name.match(/50[0-9]{2}$/)),
      },
      {
        name: "only electronic vote",
        condition: (entity) => entity.uidLong === "moscow_city",
        // condition: entity => entity.children.find(c => (c.level.id === 3 && c.name.match(/50[0-9]{2}$/))),
        filter: (child) =>
          child.level.id !== 3 || child.name.match(/50[0-9]{2}$/),
      },
    ],
    presets: false,
    sourceLang: "ru",
    sourceUrl:
      "http://www.vybory.izbirkom.ru/region/region/izbirkom?action=show&root=1&tvd=100100225883177&vrn=100100225883172&region=0&global=1&sub_region=0&prver=0&pronetvd=0&vibid=100100225883177&type=233",
    sourceName: "izbirkom.ru",
  },

  /**
   * Khabarovsk gouverneur 2021
   */

  /* {
    country: "khabarovsk",
    type: "gubernatorial",
    year: "2021",
    groupBy: "candidates",
    colorBy: "candidates", // "turnout", //
    datasets: [
      {
        id: 0,
        name: "gubernatorial",
        // countingStatus: 100,
        results: null, // "https://space.dekoder.org/elections/2020-russia-constitution/results/results_05_Jul_2020_16_23.csv",
        dateUpdated: new Date("29 Sep 2021 20:00:00 GMT+3"),
        socialImages: {
          de: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_de.png",
          en: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_en.png",
          ru: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_ru.png",
          uk: "https://specials.dekoder.org/content/uploads/2021/09/duma_2021_ru.png",
        },
      },
    ],
    title: {
      de: "Vorgezogene Gouverneurswahl in der Region Chabarowsk 2021",
      en: "Early gubernatorial elections in Khabarovsk region 2021",
      ru: "Досрочные выборы Губернатора Хабаровского края 2021 г.",
      uk: "Досрочные выборы Губернатора Хабаровского края 2021 г.",
    },
    candidates: "/elections/2021-khabarovsk-gubernatorial/candidates.json",
    stations: [
      // "https://space.dekoder.org/elections/2021-russia-duma/duma_2021_direct_test.csv",
      "https://space.dekoder.org/elections/2021-russia-duma/duma_2021_1633085245.csv",
    ],
    combined: true,
    levels: [
      {
        id: 0,
        name: "country",
        default: "Overall result",
        groupBy: "candidates",
      },
      {
        id: 1,
        name: "region",
        key: "region",
        groupBy: "candidates",
        uidKeys: ["region"],
      },
      {
        id: 2,
        name: "tik",
        key: "tik",
        groupBy: "candidates",
        uidKeys: ["region", "name"],
      },
      {
        id: 3,
        name: "station",
        key: "uik",
        groupBy: "candidates",
        prefix: "УИК №",
        uidKeys: ["region", "name"],
      },
    ],
    presets: false,
    sourceLang: "ru",
    sourceUrl:
      "http://www.khabarovsk.vybory.izbirkom.ru/region/region/khabarovsk?action=show&root=1&tvd=22720001116505&vrn=22720001116501&region=27&global=&sub_region=27&prver=0&pronetvd=null&vibid=22720001116505&type=222",
    sourceName: "izbirkom.ru",
  },*/

  /**
   * RUSSIA CONSTITUTIONAL REFERENDUM 2020
   */

  {
    country: "russia",
    type: "constitution",
    year: "2020",
    groupBy: "candidates",
    colorBy: "turnout",
    datasets: [
      {
        id: 0,
        name: "Results",
        countingStatus: 100,
        results: null, // "https://space.dekoder.org/elections/2020-russia-constitution/results/results_05_Jul_2020_16_23.csv",
        dateUpdated: new Date("06 Jul 2020 19:05:00 GMT+3"),
        socialImages: {
          de: "https://specials.dekoder.org/content/uploads/2020/07/russia_const_ref_social.png",
          en: "https://specials.dekoder.org/content/uploads/2020/07/russia_const_ref_social.png",
          uk: "https://specials.dekoder.org/content/uploads/2020/07/russia_const_ref_social.png",
          ru: "https://specials.dekoder.org/content/uploads/2020/07/russia_const_ref_social.png",
      }},
    ],
    title: {
      de: "Abstimmung zur Verfassungsänderung 2020",
      en: "Constitutional Referendum in Russia 2020",
      ru: "Голосование по поправкам к Конституции 2020",
      uk: "Голосування по поправках до Конституції 2020",
    },
    candidates:
      "/elections/2020-russia-constitution/candidates/candidates.json",
    stations: [
      "https://space.dekoder.org/elections/2020-russia-constitution/results_06_Jul_2020_19_05_comb.csv", // stations-2020   // cik_uik_28_06_2020_new2
    ],
    combined: true,
    levels: [
      {
        id: 0,
        name: "country",
        default: "Overall result",
        groupBy: "candidates",
      },
      {
        id: 1,
        name: "region",
        key: "region",
        groupBy: "candidates",
        uidKeys: ["region"],
      },
      {
        id: 2,
        name: "tik",
        key: "tik",
        groupBy: "candidates",
        uidKeys: ["region", "name"],
      },
      {
        id: 3,
        name: "station",
        key: "uik",
        groupBy: "candidates",
        prefix: "УИК №",
        uidKeys: ["region", "name"],
      },
    ],
    presets: false,
    sourceLang: "ru",
    sourceUrl:
      "http://www.vybory.izbirkom.ru/region/region/izbirkom?action=show&root=1&tvd=100100163596969&vrn=100100163596966&region=0&global=1&sub_region=0&prver=0&pronetvd=null&vibid=100100163596969&type=465",
    sourceName: "izbirkom.ru",
  },

  /**
   * MOSCOW CITY DUMA
   */
  /*
  {
    country: "moscow",
    type: "city-duma",
    year: "2014",
    groupBy: "parties",
    datasets: [
      {
        id: 0,
        // slug: 'party-votes',
        name: "direct-votes",
        results: "https://space.dekoder.org/elections/2014-moscow-city-duma/results/results_b.csv",
        // totalStations: 29885, // 29674,
        countingStatus: 100,
        socialImage: "https://space.dekoder.org/elections/2019-ukraine-parliamentary/social/de.png",
      },
    ],
    title: {
      de: "Wahlen zur Moskauer Stadtduma 2014",
      en: "City Duma Elections in Moscow 2014",
      uk: "Выборы в городскую думу Москвы 2014",
      ru: "Выборы в городскую думу Москвы 2014",
    },
    uidKeys: ["uik"],
    levels: [
      {
        id: 0,
        name: "country",
        default: "Overall result",
        groupBy: "candidates",
      },
      { id: 1, name: "station", key: "uik", groupBy: "candidates" },
    ],
    candidates:
      "/elections/2014-moscow-city-duma/candidates/candidates.json",
    stations: ["https://space.dekoder.org/elections/2014-moscow-city-duma/stations/stations_a.csv"],
    presets: false,
    sourceLang: "ru",
    sourceUrl:
      "http://www.moscow_city.vybory.izbirkom.ru/region/moscow_city?action=show&root_a=772000001&vrn=27720001539308&region=77&global=null&type=0&sub_region=77&prver=0&pronetvd=null",
    sourceName: "cvk.gov.ua",
  },
*/

  {
    country: "ukraine",
    type: "parliamentary",
    year: "2019",
    groupBy: "candidates",
    datasets: [
      {
        id: 0,
        slug: "party-votes",
        name: "multi-mandate",
        results:
          "https://space.dekoder.org/elections/2019-ukraine-parliamentary/results/multi/results.meta.txt",
        totalStations: 29885, // 29674,
        countingStatus: 100,
        socialImages: {
          de: "https://space.dekoder.org/elections/2019-ukraine-parliamentary/social/de.png",
          en: "https://space.dekoder.org/elections/2019-ukraine-parliamentary/social/en.png",
          uk: "https://space.dekoder.org/elections/2019-ukraine-parliamentary/social/ua.png",
          ru: "https://space.dekoder.org/elections/2019-ukraine-parliamentary/social/ru.png",
        },
      },
      /*
      {
        id: 0,
        slug: "direct-votes",
        name: "single-mandate",
        // results: 'https://space.dekoder.org/elections/2019-ukraine-parliamentary/results/single/results.meta.txt',
        totalStations: 29885, // 29674,
        // countingStatus: 100
      },*/
    ],
    title: {
      de: "Parlamentswahl in der Ukraine 2019",
      en: "Parliamentary Elections in Ukraine 2019",
      uk: "Вибори до Верховної Ради 2019 року",
      ru: "Выборы в Верховную раду Украины 2019 года",
    },
    candidates:
      "/elections/2019-ukraine-parliamentary/parties/parties_2019.json",
    stations: [
      "https://space.dekoder.org/elections/2019-ukraine-presidential/stations/all_ua_stations_1594048594.csv",
    ],
    presets: false,
    sourceLang: "uk",
    sourceUrl: "https://www.cvk.gov.ua/pls/vnd2019/wp306_npt001f01=919.html",
    sourceName: "cvk.gov.ua",
  },

  /* {
    country: 'ukraine',
    type: 'parliamentary',
    year: '2014',
    groupBy: "candidates",
    datasets: [
      {
        id: 0, 
        slug: 'party-votes',
        name: 'multi-mandate', 
        results: 'https://space.dekoder.org/elections/2014-ukraine-parliamentary/results/party/results.meta.txt',
        totalStations: 29674,
        countingStatus: 100,
        socialImages: {
          de: 'https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png',
          en: 'https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png',
          ua: 'https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png',
          ru: 'https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png'
        },
      },
    ],
    title: {
      de: 'Parlamentswahl in der Ukraine 2014',
      en: 'Parliamentary Elections in Ukraine 2014',
      ua: 'Вибори до Верховної Ради 2014 року',
      ru: 'Выборы в Верховную раду Украины 2014 года'
    },
    candidates: '/elections/2014-ukraine-parliamentary/parties/parties_2019.json',
    stations: ['https://space.dekoder.org/elections/2019-ukraine-presidential/stations/stations_2019_g.csv'],
    presets: false,
    sourceLang: 'uk',
    sourceUrl: 'https://www.cvk.gov.ua/pls/vnd2014/wp00552e6.html?PT001F01=910',
    sourceName: 'cvk.gov.ua'
  }, */

  {
    country: "ukraine",
    type: "presidential",
    year: "2019",
    groupBy: "candidates",
    datasets: [
      {
        id: 0,
        slug: "round-1",
        name: "1st round",
        results:
          "https://space.dekoder.org/elections/2019-ukraine-presidential/results/round-1/results.meta.txt",
        totalStations: 29989,
        countingStatus: 100,
        socialImages: {
          de: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/de.png",
          en: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/en.png",
          uk: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/ua.png",
          ru: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/ua.png",
        },
      },
      {
        id: 1,
        slug: "round-2",
        name: "2nd round",
        results:
          "https://space.dekoder.org/elections/2019-ukraine-presidential/results/round-2/results.meta.txt",
        totalStations: 29989,
        // countingStatus: 0,
        socialImages: {
          de: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png",
          en: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png",
          uk: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png",
          ru: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/round-2-en-b.png",
        }
        },
    ],
    title: {
      de: "Präsidentschaftswahl in der Ukraine 2019",
      en: "Presidential Elections in Ukraine 2019",
      uk: "Вибори Президента України 2019 року",
      ru: "Выборы Президента Украины 2019 года",
    },
    candidates:
      "/elections/2019-ukraine-presidential/candidates/candidates_2019.json",
    stations: [
      "https://space.dekoder.org/elections/2019-ukraine-presidential/stations/all_ua_stations_1594048594.csv",
    ],
    presets: false,
    sourceLang: "uk",
    sourceUrl: "https://www.cvk.gov.ua/pls/vp2019/wp335pt001f01=719.html",
    sourceName: "cvk.gov.ua",
  },

  /*
  {
    country: "ukraine",
    type: "presidential",
    year: "2014",
    groupBy: "candidates",
    title: {
      de: "Präsidentschaftswahl in der Ukraine 2014",
      en: "Presidential Elections in Ukraine 2014",
      uk: "Вибори Президента України 2014 року",
      ru: "Выборы Президента Украины 2014 года",
    },
    datasets: [
      {
        id: 0,
        uid: "2014-presidential",
        name: "1st round",
        results:
          "https://space.dekoder.org/elections/2014-ukraine-presidential/results/results.meta.txt",
        countingStatus: 100,
        socialImages: {
          de: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/de.png",
          en: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/en.png",
          uk: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/ua.png",
          ru: "https://space.dekoder.org/elections/2019-ukraine-presidential/social/ua.png",
        },
      },
    ],
    candidates: "/elections/2014-ukraine-presidential/candidates_2014.json",
    stations: ["https://space.dekoder.org/elections/2014-ukraine-presidential/stations_2014.csv"],
    presets: false,
    sourceLang: "ua",
    sourceUrl: "https://www.cvk.gov.ua/pls/vp2014/wp001.html",
    sourceName: "cvk.gov.ua",
  },*/
]
