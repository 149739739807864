import { useMemo, useState, useEffect } from "react"

const useStatistics = (
  loaded,
  currEntity,
  currDs,
  highestEntity,
  updTrigger
) => {
  const [hist, scatter] = useHistogramData(
    loaded,
    currEntity,
    currDs,
    updTrigger
  )
  const peak = usePeak(loaded, highestEntity, currDs)
  const colorStops = useColorStops(peak, highestEntity)
  const turnout = useTurnout(currEntity, currDs, updTrigger)
  return [colorStops, hist, scatter, turnout]
}

export default useStatistics

function useHistogramData(loaded, currEntity, currDs = {}, updTrigger) {
  const histogramData = useMemo(() => {
    ;[].push(updTrigger)
    if (!loaded || !currEntity) return []
    // console.log("NEW HISTOG")
    const dsId = currDs?.id
    return currEntity.histogramData(dsId)
  }, [loaded, currEntity, currDs?.id, updTrigger])
  const scatterPlotData = useMemo(() => {
    ;[].push(updTrigger)
    if (!loaded || !currEntity) return []
    const dsId = currDs.id
    return currEntity.scatterPlotData(dsId)
  }, [loaded, currEntity, currDs?.id, updTrigger])
  return [histogramData, scatterPlotData]
}

function usePeak(loaded, highestEntity, currDs = {}) {
  return useMemo(() => {
    if (!loaded || !highestEntity) return 0
    const dsId = currDs.id
    const histogramData = highestEntity.histogramData(dsId)
    const result = !histogramData.length
      ? 0
      : histogramData[0].data.reduce(
          // 0: 1st candidate
          (acc, curr) => {
            return curr.y > acc.y ? { x: curr.x, y: curr.y } : acc
          },
          { x: 0, y: 0 }
        ).x || 0
    return result
  }, [highestEntity, currDs?.id, loaded])
}

function useColorStops(peak, highestEntity) {
  const [savedCountryStops, setColorStopsCountry] = useState()
  useEffect(() => {
    if (!highestEntity) return
    if (!peak) return
    const newStops = getColorStops(peak)
    if (newStops && !isSameObj(newStops, savedCountryStops))
      setColorStopsCountry(newStops)
  }, [peak, highestEntity, savedCountryStops])
  return savedCountryStops
}

function useTurnout(currEntity, currDs, updTrigger) {
  return useMemo(() => {
    ;[].push(updTrigger)
    const dsId = currDs?.id
    if (!currEntity || !currEntity.getResult(dsId)) return 0
    return currEntity.getResult(dsId).turnout
  }, [currEntity, currDs?.id, updTrigger])
}

/**
 * helpers
 */

function getColorStops(peak) {
  const colorCenter = peak
  const min = Math.max(colorCenter - 30, 0)
  const max = Math.min(colorCenter + 30, 100)
  return [
    [min, "#26C6DA"],
    [colorCenter, "#FDD835"], // #f1f075
    [max, "#F44336"], // #e55e5e
  ]
}

function isSameObj(obj1, obj2) {
  const result = JSON.stringify(obj1) === JSON.stringify(obj2)
  // console.log("IS SAME", result, obj1, obj2)
  return result
}
