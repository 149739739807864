import { useMemo } from "react"
import t from "../../modules/translations"

export function useOptions(
  datasetFilter,
  elections,
  currElection,
  currDs,
  lang
) {
  return useMemo(() => {
    const electionsNotHidden = elections.filter((e) => !e.hidden)
    let options = []
    let selectedOption = {}
    let placeholder = t("Select", lang.id)

    if (datasetFilter.year) {
      placeholder = t("dataset", lang.id)
      options = elections
        .filter(
          (e) =>
            e.country === datasetFilter.country &&
            e.type === datasetFilter.type &&
            e.year === datasetFilter.year
        )[0]
        .datasets.map((ds) => ({
          value: ds.id,
          label: t(ds.name, lang.id),
          type: "dataset",
          // isDisabled: !ds.results,
        }))
      if (
        currElection &&
        datasetFilter.country === currElection.country &&
        datasetFilter.type === currElection.type &&
        datasetFilter.year === currElection.year
      )
        selectedOption = {
          value: currDs.id,
          label: t(currDs.name, lang.id),
          type: "dataset",
        }
    } else if (datasetFilter.type) {
      placeholder = t("year", lang.id)
      elections
        .filter(
          (e) =>
            e.country === datasetFilter.country && e.type === datasetFilter.type
        )
        .forEach((e) => {
          if (!options.find((o) => o.value === e.year))
            options.push({ value: e.year, label: e.year, type: "year" })
        })
      if (
        currElection &&
        datasetFilter.country === currElection.country &&
        datasetFilter.type === currElection.type
      ) {
        selectedOption = {
          value: currElection.year,
          label: currElection.year,
          type: "year",
        }
      }
    } else if (datasetFilter.country) {
      placeholder = t("election type", lang.id)
      const filteredElections = elections.filter(
        (e) => e.country === datasetFilter.country
      )
      filteredElections.forEach((e) => {
        if (!options.find((o) => o.value === e.type)) {
          const count = getCountByPropMatch("type", e, filteredElections)
          options.push({
            value: e.type,
            label: `${t(e.type, lang.id)} (${count})`,
            type: "type",
          })
        }
      })
      if (currElection && datasetFilter.country === currElection?.country) {
        selectedOption = {
          value: currElection?.type,
          label: t(currElection?.type, lang.id),
          type: "type",
        }
      }
    } else {
      placeholder = t("country", lang.id)
      electionsNotHidden.forEach((e) => {
        if (!options.find((o) => o.value === e.country)) {
          const count = getCountByPropMatch("country", e, elections)
          options.push({
            value: e.country,
            label: `${t(e.country, lang.id)} (${count})`,
            type: "country",
          })
        }
      })
      selectedOption = {
        value: currElection?.country,
        label: t(currElection?.country, lang.id),
        type: "country",
      }
    }
    return [options, selectedOption, placeholder]
  }, [datasetFilter, elections, currElection, currDs, lang.id])
}

function getCountByPropMatch(propName, currEl, elections = []) {
  return elections.filter((el) => el[propName] === currEl[propName]).length
}
