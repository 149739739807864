import React, { useEffect, useCallback, useRef } from "react"
import { MapboxOverlay, MapboxOverlayProps } from "@deck.gl/mapbox/typed"
import Map, {
  useControl,
  NavigationControl,
  MapRef,
  Projection,
} from "react-map-gl"
import { getPadding } from "../map-mapbox/map"
import { useLayers } from "./layers"
import { _GlobeView } from "deck.gl"

const INITIAL_VIEW_STATE = {
  latitude: 50.45,
  longitude: 30.5,
  zoom: 4,
}

const MAP_STYLE = "mapbox://styles/mapbox/light-v9?optimize=true"

function DeckGLOverlay(props: MapboxOverlayProps) {
  const overlay = useControl(() => new MapboxOverlay(props))
  overlay.setProps(props)
  return null
}

export const DeckGlMap = ({
  geoJson,
  onPointClick,
  allCands,
  bounds,
  selectedFilter,
  dsId = 0,
  colorBy,
  colorStops,
  selected,
}) => {
  const mapRef = useRef<MapRef>(null)

  const onClick = useCallback(
    (info) => {
      if (info.object) {
        onPointClick(info.object.properties.uid)
      }
    },
    [onPointClick]
  )

  const layers = useLayers(
    geoJson,
    allCands,
    onClick,
    selectedFilter,
    dsId,
    colorBy,
    colorStops,
    selected
  )

  useEffect(() => {
    if (!bounds || !mapRef?.current) return
    mapRef.current.fitBounds(bounds, {
      padding: getPadding(),
    })
  }, [bounds, mapRef])

  return (
    <Map
      ref={mapRef}
      initialViewState={INITIAL_VIEW_STATE}
      mapStyle={MAP_STYLE}
      mapboxAccessToken={process.env.GATSBY_MAPBOX_KEY}
    >
      <DeckGLOverlay layers={layers} interleaved={false} />
      <NavigationControl position="bottom-left" />
    </Map>
  )
}
