import chroma from "chroma-js"
class CandidateManager {
  constructor(jsonUrl, baseUrl) {
    this.candidates = []
    this.parties = []
    if (typeof window !== "undefined") window.cm = this
    if (jsonUrl) this.loadJson(`${jsonUrl}`) // `${baseUrl || ""}${jsonUrl}`
  }

  loadJson(jsonUrl) {
    this.candidates = []
    // console.log(jsonUrl)
    fetch(jsonUrl)
      .then((r) => r.json())
      .then((candidateArray) => {
        if (candidateArray)
          candidateArray.forEach((c) => {
            if (c.party) c.party = this.addParty(c.party)
            this.getOrAdd(c)
          })
      })
      .catch((e) => {
        console.log(e, jsonUrl)
      })
  }

  addParty(partyName) {
    const uid = getPartyUid(partyName)
    const existingParty = this.findParty(uid)
    if (existingParty) return existingParty
    const newParty = new Party(partyName, this.parties.length, this)
    this.parties.push(newParty)
    return newParty
  }

  getOrAdd(props) {
    const { name = "", firstName = "", patronymic = "" } = props
    const uid = getCandidateUid(name, firstName, patronymic)
    const existingCand = this.candidates.find((c) => c.uid === uid)
    if (existingCand) return existingCand
    const newCandidate = new Candidate(props, this)
    this.candidates.push(newCandidate)
    return newCandidate
  }

  findParty(uid) {
    return this.parties.find((p) => p.uid === uid)
  }
}

export default CandidateManager

class Candidate {
  constructor(props, candidateManager) {
    const { name, firstName, patronymic } = props
    const { image, color, party, emoji } = props
    this.name = uppercaseFirstLetters(name)
    this.id = candidateManager.candidates.length
    this.uid = getCandidateUid(name, firstName, patronymic)
    this.firstName = uppercaseFirstLetters(firstName)
    this.patronymic = uppercaseFirstLetters(patronymic)
    this.image = image
    this.color = color
      ? color
      : party
        ? party.color
        : getRandomColor() || defaultColors()[this.id] || "#33ff77"
    this.candidateManager = candidateManager
    this.party = party
    this.emoji = emoji || ""
    this.active = true
    this.selected = false
  }

  get hexColor() {
    return chroma(this.color).hex()
  }

  selectUnselect(callback, multipleSelect, groupBy = "candidates") {
    if (!this.selected) {
      // select
      this.selected = true
      this.active = true
      if (multipleSelect) {
        if (typeof callback === "function") callback()
      } else {
        // else: deactivate all other candidates
        this.candidateManager[groupBy]
          .filter((c) => c !== this)
          .forEach((c, i, arr) => {
            c.active = false
            c.selected = false
            if (i + 1 === arr.length && typeof callback === "function")
              callback()
          })
      }
    } else {
      // unselect
      this.selected = false
      // reactivate all other candidates
      this.candidateManager[groupBy].forEach((c, i, arr) => {
        c.active = true
        if (i + 1 === arr.length && typeof callback === "function") callback()
      })
    }
  }
}

class Party extends Candidate {
  constructor(partyName, id, candidateManager) {
    super(partyName, id, null, null, null, null, null, candidateManager)
  }
  selectUnselect(callback, multipleSelect) {
    super.selectUnselect(callback, multipleSelect, "parties")
  }
}

function uppercaseFirstLetters(string) {
  if (!string) return
  return string
    .toLowerCase()
    .split(" ")
    .map((word) =>
      word.length > 1 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    )
    .join(" ")
}

function getCandidateUid(name, firstName, patronymic) {
  // return [name, firstName || "", patronymic || ""].join(" ").trim().toLowerCase()
  return [name, firstName || ""].join(" ").trim().toLowerCase()
}

function getPartyUid(partyName) {
  return partyName.toLowerCase()
}

function defaultColors() {
  return [
    "#dbd56e",
    "#88ab75",
    "#2d93ad",
    "#7d7c84",
    "#de8f6e",
    "#da2c38",
    "#226f54",
    "#87c38f",
    "#4f0bb0",
    "#43291f",
    "#dbd56e",
    "#88ab75",
    "#2d93ad",
    "#7d7c84",
    "#de8f6e",
    "#da2c38",
    "#226f54",
    "#87c38f",
    "#4f0bb0",
    "#43291f",
  ]
}

function getRandomColor() {
  var letters = "0123456789ABCDEF"
  var color = "#"
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
