const langs = [
  { id: "en", locale: "en-US", name: "English", nameShort: "En" },
  { id: "de", locale: "de-DE", name: "Deutsch", nameShort: "De" },
  { id: "uk", locale: "uk-UA", name: "Українська", nameShort: "Ук" },
  { id: "ru", locale: "ru-RU", name: "Русский", nameShort: "Ру" },
]

exports.langs = langs

exports.legacyLangs = [
  ...langs,
  { id: "ua", locale: "uk-UA", name: "Українська", nameShort: "Ук" },
]

exports.baseUrl = "https://elections.dekoder.org"
