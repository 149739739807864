import { useState, useCallback, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"

const DEFAULT_STATE = {}

function useLoadingStatus(currElection) {
  const [state, setState] = useState(DEFAULT_STATE)
  useEffect(() => {
    setState(DEFAULT_STATE)
  }, [currElection])
  const setProgressForTask = useCallback((taskId, progress) => {
    setState((s) => ({
      ...s,
      [taskId]: progress,
    }))
  }, [])
  const newTask = useCallback(() => {
    const taskId = uuidv4()
    setProgressForTask(taskId, 0)
    const setProgress = (progress) => setProgressForTask(taskId, progress)
    return setProgress
  }, [setProgressForTask])
  const taskIds = Object.keys(state)
  const totalProgress = taskIds.reduce((acc, taskId) => {
    const taskProgress = state[taskId]
    return acc + (taskProgress * 1) / taskIds.length
  }, 0)
  /*console.log(
    `tasks: ${taskIds.length}. total progress: ${totalProgress}`,
    state
  )*/
  return [totalProgress, newTask]
}

export default useLoadingStatus
