import React, { useContext } from "react"
import LangContext from "../../contexts/lang"
import t from "../../modules/translations"
import "./color-legend.scss"

const ColorLegend = ({ colorStops, turnout, hoverTurnout }) => {
  const lang = useContext(LangContext)
  const turnoutStr = t("Turnout", lang.id)
  const gradientStops =
    colorStops && colorStops.length
      ? colorStops.map((s) => `${s[1]} ${s[0]}%`).join(", ")
      : null
  return (
    <div
      className="color-legend"
      style={{
        background: gradientStops
          ? `linear-gradient(to right, ${gradientStops})`
          : "transparent",
      }}
    >
      <div>{turnoutStr}</div>
      <div
        className="avg-turnout"
        style={{ left: `${Math.round(turnout)}%` }}
      />
      {!!hoverTurnout && (
        <div
          className="hover-turnout"
          style={{ left: `${Math.round(hoverTurnout)}%` }}
        />
      )}
      <div className="gradient-grid">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  )
}

export default ColorLegend
