import React, { useContext, useState } from "react"
import { Link } from "gatsby"
import LangContext from "../../contexts/lang"
import "./lang-switcher.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons"
import { isMobile } from "../../modules/utils"

const LangSwitcher = ({ allLangs, selected = {} }) => {
  const [open, setOpen] = useState(false)
  const lang = useContext(LangContext)
  return (
    <>
      <div className="language-switcher">
        <button className="language-button" onClick={() => setOpen((o) => !o)}>
          <FontAwesomeIcon icon={faGlobeEurope} />
          <span
            className="language-button-name"
            data-long={lang.name}
            data-short={lang.id}
          />
        </button>
      </div>
      <ul className={"language-menu" + (open ? " open" : " closed")}>
        {allLangs
          .filter((l) => l.name)
          .filter((l) => l.id !== lang.id)
          .map((l) => {
            const className = `language-option${
              l.id === lang.id ? " selected" : ""
            }`
            return (
              <li key={l.id}>
                <Link
                  className={className}
                  to={l.destination}
                  onClick={() => setOpen(false)}
                >
                  {isMobile() ? l.id : l.name}
                </Link>
              </li>
            )
          })}
      </ul>
    </>
  )
}

export default LangSwitcher
