import { useState, useCallback } from "react"

function useDatasetFilter(electionProps) {
  const [currElection, currDs, onDsChange] = electionProps
  const [datasetFilter, setDatasetFilter] = useState({
    country: currElection?.country,
    type: currElection?.type,
    year: currElection?.year,
    dataset: currDs?.id,
  })

  const setDatasetFilterByType = useCallback(
    (value, type) => {
      function getDatasetFilterByType(ntype, value) {
        const { country, type, year } = datasetFilter
        switch (ntype) {
          case "root":
            return { country: null, type: null, year: null, dataset: null }
          case "country":
            return { country: value, type: null, year: null, dataset: null }
          case "type":
            return { country, type: value, year: null, dataset: null }
          case "year":
            return {
              country,
              type,
              year: value,
              dataset: null,
            }
          case "dataset":
            return {
              country,
              type,
              year,
              dataset: value,
            }
          default:
            return
        }
      }
      const dsFilter = getDatasetFilterByType(type, value)
      setDatasetFilter(dsFilter)
      if (type === "dataset") onDsChange(dsFilter, value)
    },
    [onDsChange, datasetFilter]
  )

  return [datasetFilter, setDatasetFilterByType]
}

export default useDatasetFilter
