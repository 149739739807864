import React from "react"
import { ResponsiveScatterPlotCanvas } from "@nivo/scatterplot"
import chroma from "chroma-js"
import "./scatter-plot.scss"

const ScatterPlot = ({ data }) => {
  data = data.map((d) => ({ ...d, color: chroma(d.color).alpha(0.5).css() }))
  return (
    <div className="scatter-plot">
      <ResponsiveScatterPlotCanvas
        data={data}
        margin={{ top: 10, right: 20, bottom: 0, left: 20 }} // bottom 20
        layers={["grid", "axes", "nodes", "mesh", "legends"]}
        colors={data.map((d) => d.color)}
        isInteractive={false}
        nodeSize={1}
        xScale={{
          type: "linear",
          min: 0,
          max: 100,
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: 100,
        }}
        gridXValues={[
          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
          90, 95, 100,
        ]}
        gridYValues={[
          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85,
          90, 95, 100,
        ]}
        axisLeft={
          null /*{
          orient: "left",
          tickSize: 2, // 3
          tickPadding: -30, // 3
          tickRotation: 0,
          // legend: turnoutStr,
          // legendOffset: 33,
          legendPosition: "middle",
          format: (v) => `${v} %`,
        }*/
        }
        axisBottom={
          null /*{
          orient: "bottom",
          tickSize: 7, // 3
          tickPadding: 5, // 3
          tickRotation: 0,
          // legend: turnoutStr,
          // legendOffset: 33,
          legendPosition: "middle",
          format: (v) => `${v} %`,
        }*/
        }
        theme={{
          axis: {
            fontFamily: "Roboto Condensed",
            legend: {
              text: {
                fontFamily: "Roboto Condensed",
                fontSize: "0.64em",
                fill: "#666666", //  hsl(0%, 0%, 40%)"
              },
            },
            ticks: {
              line: {
                stroke: "rgba(150, 150, 150, 0.5)",
              },
              text: {
                fontFamily: "Roboto Condensed",
                fontSize: "0.64em",
                backgroundColor: "white",
                fill: "#666666", //  hsl(0%, 0%, 40%)"
              },
            },
          },
          grid: {
            line: {
              stroke: "rgba(180, 180, 180, 0.2)",
              strokeDasharray: "4 4",
            },
          },
        }}
      />
    </div>
  )
}

export default React.memo(ScatterPlot)
