import React, { useContext } from "react"
import LangContext from "../../contexts/lang"
import "./site-logos.scss"

const SiteLogos = ({ logos = [] }) => {
  const lang = useContext(LangContext)
  return (
    <div className="start-page-logo-container">
      {logos.map((logo, i) => {
        const logoUrl =
          typeof logo.url === "object" ? logo.url[lang.id] : logo.url
        return (
          <div
            key={i}
            className="start-page-logo-outer"
            style={{ width: `${(logo.scale || 1) * 100}%` }}
          >
            <a href={logoUrl} target="_blank" rel="noopener noreferrer">
              <img src={logo.src} className="start-page-logo" alt="logo" />
            </a>
          </div>
        )
      })}
    </div>
  )
}

export default SiteLogos
