import React, { useContext, useEffect, useState } from "react"
import Select from "react-select"
import t from "../../modules/translations"
import LangContext from "../../contexts/lang"

const LocationSelector = ({
  onLocationClick,
  currEntity = { directChildren: [] },
  sourceLang,
}) => {
  const lang = useContext(LangContext)
  function onLocationChange(e, level) {
    // console.log("ON LOC CHANGE", e, level)
    onLocationClick(e ? e.value : false, level)
  }
  const [options, setOptions] = useState([])
  useEffect(() => {
    const newOptions = currEntity.directChildren
      .filter((o) => o.value !== "undefined")
      .map((r) => ({
        value: r.uid,
        label: t(r.displayName, lang.id, sourceLang),
      }))
      .sort((a, b) => (a.label || "").localeCompare(b.label || ""))
    setOptions(newOptions)
  }, [currEntity, lang.id, sourceLang])

  // console.log("HH", currEntity, options)

  if (!options.length) return null
  return (
    <div className="info-panel-selector clickable info-panel-region">
      <Select
        options={options}
        isClearable={true}
        value={
          currEntity.region
            ? {
                value: currEntity.region.name,
                label: t(currEntity.region.displayName, lang.id, sourceLang),
              }
            : ""
        }
        onChange={(e) => onLocationChange(e, currEntity.childLevelId)}
        placeholder={t("choose", lang.id) + " ..."}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
      />
    </div>
  )
}

export default LocationSelector
