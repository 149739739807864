// import StationWorker from "worker-loader!./station.worker"
import Papa from "papaparse"
import { Result, SummarizedResult } from "./result-loader"
import { region_dict_rev } from "./ru-regions"

const DEFAULT_LEVELS = [
  {
    id: 0,
    name: "country",
    default: "Overall result",
    groupBy: "candidates",
  },
  {
    id: 1,
    name: "region",
    key: "region",
    groupBy: "candidates",
    uidKeys: ["region"], // regKey
  },
  {
    id: 2,
    name: "tik",
    key: "tik",
    groupBy: "candidates",
    uidKeys: ["region", "name"], // region
  },
  {
    id: 3,
    name: "station",
    key: "uik",
    groupBy: "candidates",
    prefix: "",
    uidKeys: ["tikId", "name"],
  },
]

class StationManager {
  constructor(currElection, candidateManager) {
    const { stations, levels, combined } = currElection
    this.urls = typeof urls === "string" ? [stations] : stations
    this.stationsByUniqueId = {}
    this.stationsByCoordinates = {}
    this.higherEntities = []
    this.higherEntitiesByInternalId = {}
    this.levels = levels || DEFAULT_LEVELS
    this.stationsLevelId = this.levels.length - 1
    this.stationsLevel = this.getLevelFromId(this.stationsLevelId)
    this.groupStationsBy = this.stationsLevel.groupBy
    this.stations = []
    this.combined = combined
    this.candidateManager = candidateManager

    if (typeof window !== "undefined") window.stationManager = this
  }

  get highestEntity() {
    return this.higherEntities.find((e) => e.level.id === 0)
  }

  getLevelFromId(levelId) {
    const level = this.levels.find((l) => l.id === levelId)
    if (!level) console.log(`level ${levelId} not found`)
    return level || {}
  }

  keyByLevel(levelId) {
    const level = this.levels.find((l) => l.id === levelId)
    return level ? level.key : false
  }

  typeByLevel(levelId) {
    const level = this.levels.find((l) => l.id === levelId) || {}
    return level.name
  }

  getLevelPrefix(levelId) {
    const level = this.levels.find((l) => l.id === levelId) || {}
    return level.prefix || ""
  }

  getEntity(uid, levelId = this.stationsLevelId, searchProp = "uid") {
    return levelId === this.stationsLevelId
      ? this.stations.find((s) => s[searchProp] === uid)
      : this.higherEntities.find((e) => e[searchProp] === uid)
  }

  getEntityByLevelLetter(uidLong, levelLetter) {
    const level = this.levels.find((l) => l.name.slice(0, 1) === levelLetter)
    if (!level) return
    return this.getEntity(uidLong, level.id, "uidLong")
  }

  getStation(stationUniqueId) {
    return this.stationsByUniqueId[stationUniqueId]
  }

  addHigherEntity(entity) {
    // if (!this.higherEntities.includes(entity))
    this.higherEntities.push(entity)
    this.higherEntitiesByInternalId[entity.internalId] = entity
  }

  getOrCreateHigherEntity(name, typeLong, level) {
    return (
      this.getHigherEntity(name, typeLong) ||
      this.createHigherEntity(name, typeLong, level)
    )
  }

  getHigherEntity(name, typeLong) {
    return this.higherEntities.find(
      (e) => e.name === name && e.typeLong === typeLong
    )
  }

  createHigherEntity(uid, props, level) {
    const entity = new HigherEntity(uid, props, level, undefined, this)
    this.addHigherEntity(entity)
    return entity
  }

  getUid(entry, level = this.stationsLevel) {
    const DEFAULT_KEYS = ["id"]
    const { uidKeys = DEFAULT_KEYS } = level
    return uidKeys.map((k) => entry[k]).join("_")
  }

  getParent(internalId) {
    return this.higherEntitiesByInternalId[internalId]
  }

  async loadStations(setProgress) {
    if (typeof window === "undefined") return
    // TODO: multiple URLs
    /* const stationsCsv = await progressFetch(this.urls[0], ({ progress }) =>
      setProgress(progress)
    ) */

    const stationsCsv = await fetch(this.urls[0], {
      // cache: "force-cache",
    }).then((r) => r.text())

    // console.log("COMBINED?", this.combined)

    const stations = await Papa.parse(stationsCsv, { header: true })
      .data // , delimiter: ";"
      // .slice(0,10000)
      .map((r) => {
        // console.log(r)
        const levelId = r.level * 1
        if (levelId < this.stationsLevelId) {
          // higher order entity
          const level = this.getLevelFromId(levelId)
          const { name, region, id } = r
          const nameParsed = region_dict_rev[name] || name
          const regionParsed = region_dict_rev[region] || region
          const uid = this.getUid(r, level)
          const entity = this.createHigherEntity(
            uid,
            { name: nameParsed, region: regionParsed, id },
            level
          )
          const parent = this.getParent(r.parent_id)
          // console.log("HE", entity, parent, r)
          if (parent) parent.addChild(entity)
          return false
        } else {
          // station
          const firstAtLocation = this.stationsByCoordinates[r.lat + r.lon]
            ? false
            : true

          if (r.center) {
            // this is not a polling station, it's is a TIK entry
            // tik.setCoordinates(r);
            return false // false entries will be filtered out below
          } else {
            // normal polling station
            const stationUid = this.getUid(r)
            const station = new Station(
              stationUid,
              r,
              this.stationsLevel,
              firstAtLocation,
              this
            )

            const parent = this.getParent(r.parent_id)
            if (parent) parent.addChild(station)

            if (r.lat && r.lon) {
              if (firstAtLocation)
                this.stationsByCoordinates[r.lat + r.lon] = station
              // first
              else {
                const firstStation = this.stationsByCoordinates[r.lat + r.lon]
                station.addFirstStationReference(firstStation)
                firstStation.addOtherStation(station)
                // console.log('double')
              }
            }
            this.stationsByUniqueId[stationUid] = station

            if (this.combined) {
              const dsId = 0 // TODO !!!
              station.addResult(dsId, r)
            }

            return station
          }
        }
      })

    setProgress(1)

    this.stations = stations.filter((s) => s)
  }
}

export default StationManager

const TURNOUT_ARR = [...Array(101).keys()]

export class Station {
  constructor(uid, props, level, firstAtLocation, stationManager) {
    const { address, name, region, lat, lon, city, id } = props
    this.address = address
    this.level = level
    this.uid = id || uid // for parent matching & map geosjon (= shorter than uid)
    this.uidLong = uid
    this.internalId = id || uid // for parent matching & map geosjon (= shorter than uid)
    this.name = `${level.prefix || ""}${name}`
    this.lat = lat * 1
    this.lon = lon * 1
    this.city = city || (this.address ? this.address.split(", ")[2] : "")
    this.isForeignStation = city ? true : false
    this.parents = []
    this.children = []
    this.station = this
    this.first = firstAtLocation
    this.otherStationsHere = []
    this.typeLong = level.name
    this.type = this.typeLong.charAt(0)
    this.regKey = region

    this._childResultsByTurnout = {}

    this.stationManager = stationManager

    this._results = {}
    this._cachedCalcedResults = {}
  }

  get results() {
    // TODO. dynamic w/ multiple ds
    return this.filterFunc // || !Object.keys(this._results).length // fallback: calc on the fly
      ? {
          [this._dsId]: this.getFilteredResults(this.filterFunc, this._dsId),
        }
      : this._results // default
  }

  getResult(dsId) {
    // calculated on the fly: much faster than precalculating all higher entities
    return this.filterFunc
      ? this.getFilteredResults(this.filterFunc, dsId)
      : this.getFilteredResults(null, dsId)
  }

  get unfilteredResults() {
    return this._results
  }

  set results(val) {
    this._results = val
  }

  addResult(dsId, r) {
    if (!this.results) this.results = {}
    const result = this.createNewResult(r)
    if (!result) return
    this.results[dsId] = result
    // this.addStationResultToHigherLevels(r, dataset, station.parents)
    this.addResultToHigherLevels(r, dsId)
  }

  addResultToHigherLevels(r, dsId) {
    // NEW: better summarize child results on demand only
    return
    /* this.parents.forEach((parent) => {
      const groupBy = parent.level.groupBy
      if (!parent.results) parent.results = {}
      if (!parent.results[dsId])
        parent.results[dsId] = new SummarizedResult(
          r,
          this.stationManager.candidateManager,
          groupBy
        )
      else parent.results[dsId].addResults(r, groupBy)
    }) */
  }

  createNewResult(r = {}) {
    const totalVotes =
      parseInt(r.allVotes) ||
      parseInt(r.totalVotes) ||
      parseInt(r.validVotes) + parseInt(r.invalidVotes)
    if (!totalVotes) return
    return new Result(
      r,
      this.stationManager.candidateManager,
      this.stationManager.groupStationsBy
    )
  }

  get directChildren() {
    return this.children.filter((c) => c.level.id === this.childLevelId)
  }

  get directParent() {
    return this.parents.find((p) => p.level.id === this.parentLevelId)
  }

  addParents(entities) {
    entities.forEach((e) => this.parents.push(e))
  }

  addOtherStation(station) {
    if (!this.otherStationsHere.includes(station))
      this.otherStationsHere.push(station)
    this.otherStationsHere.forEach((st) => {
      if (!st.otherStationsHere.includes(this)) st.otherStationsHere.push(this)
      if (!st.otherStationsHere.includes(station) && st !== station)
        st.otherStationsHere.push(station)
    })
  }

  addFirstStationReference(station) {
    this.firstStationReference = station
  }

  get displayName() {
    return this.name
  }

  get filteredChildren() {
    return this.filterFunc
      ? this.children.filter(this.filterFunc)
      : this.children
  }

  childResultsByTurnout(dsId) {
    // to: rounded
    if (this._childResultsByTurnout[dsId])
      return this._childResultsByTurnout[dsId]

    const stations = this.filteredChildren.length
      ? this.filteredChildren.filter(
          (c) => c.typeLong === "station" && c.results && c.results[dsId]
        )
      : [this, ...this.otherStationsHere]
    this._childResultsByTurnout[dsId] = stations.reduce((acc, s) => {
      if (!s.results || !s.results[dsId]) return acc
      const to = Math.floor(s.results[dsId].turnout) // Math.round
      if (!acc[to]) acc[to] = [s.results[dsId]]
      else acc[to].push(s.results[dsId])
      return acc
    }, {})
    return this._childResultsByTurnout[dsId]
  }

  histogramData(dsId) {
    const result = this.getResult(dsId)
    if (!result) return []
    const cands = result.sortedCandidates.slice(0, 5)
    let data = cands.map((c) => ({
      id: c.candidate.name,
      uid: c.candidate.uid,
      color: c.candidate.color,
      data: [],
    }))

    const childResultsByTurnout = this.childResultsByTurnout(dsId)

    TURNOUT_ARR.forEach((to) => {
      data.forEach((d) => {
        const candUid = d.uid
        const candVotesTotal = (childResultsByTurnout[to] || []).reduce(
          (acc, r) => {
            const cand = r.candidates.find(
              (c) => c.candidate.uid === candUid
            ) || { votes: 0 }
            return acc + cand.votes
          },
          0
        )
        d.data.push({ x: to, y: candVotesTotal })
      })
    })
    return data
  }

  scatterPlotData(dsId) {
    const result = this.getResult(dsId)
    if (!result) return []
    const cands = result.sortedCandidates.slice(0, 4)
    let data = cands.map((c) => ({
      id: c.candidate.name,
      uid: c.candidate.uid,
      color: c.candidate.hexColor,
      data: [],
    }))

    // console.log("RECALCING SCPD")

    const stations = this.filteredChildren.length
      ? this.filteredChildren.filter(
          (c) => c.typeLong === "station" && c.results && c.results[dsId]
        )
      : [this, ...this.otherStationsHere]

    stations
      .slice(0, 10000)
      .map((s) => s.results[dsId])
      .forEach((r) => {
        const turnout = r.exactTurnout
        data.forEach((d) => {
          const candUid = d.uid
          const cand = r.candidates.find((c) => c.candidate.uid === candUid)
          if (!cand) return
          const point = { x: turnout, y: cand.exactShare }
          d.data.push(point)
        })
      })

    return data
  }

  // TODO: live custom filters
  setFilterFunc(filterFunc, dsId) {
    this._dsId = dsId // TODO ... dirty ...
    this._childResultsByTurnout = {} // reset to recalculate histogrammm
    this.filterFunc = filterFunc
  }

  getFilteredResults(customFilter, dsId = 0, groupBy = "candidates") {
    // const FILTER = entity => !(entity.level.id === 3 && entity.name.match(/50[0-9]{2}$/))
    /* if (typeof customFilter !== "function") {
      console.error("custom filter not a function", customFilter)
      return this._results[dsId]
    } */
    // TODO dsId, groupBy =
    // console.log("HI", this)

    if (!customFilter && this._cachedCalcedResults[dsId]) {
      return this._cachedCalcedResults[dsId]
    }

    if (this.children.length) {
      // console.log("CUSTOM FILTER", customFilter, typeof customFilter, this)

      const accumulator = new SummarizedResult(
        null,
        this.stationManager.candidateManager,
        groupBy
      )
      const res = this.children
        .filter((c) => c.level.id === this.stationManager.stationsLevelId)
        .filter(typeof customFilter === "function" ? customFilter : (c) => c)
        .reduce((acc, curr) => {
          const r = curr._results[dsId]
          if (r) acc.addResults(r, groupBy, true)
          return acc
        }, accumulator)
      // console.log("RES", res)
      if (!customFilter) {
        this._cachedCalcedResults[dsId] = res
      }
      return res
    } else {
      return this._results[dsId]
    }
  }
}

// Country, Region, Tik
class HigherEntity extends Station {
  addChild(entity) {
    this.children.push(entity)
    entity.addParents([this])
    // console.log("added", entity, "as child to", this, "and also trying to", this.directParent)
    if (this.directParent) this.directParent.addChild(entity)
  }

  get childLevelId() {
    return this.level.id + 1
  }

  get parentLevelId() {
    return this.level.id - 1
  }
}
