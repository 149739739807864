import { useEffect } from "react"
import {
  breakpointMobile,
  breakpointTablet,
} from "../components/app/globals.module.scss"

const BREAKPOINT_MOBILE = parseInt((breakpointMobile || "").replace("px", ""))
const BREAKPOINT_TABLET = parseInt((breakpointTablet || "").replace("px", ""))

export function isMobile() {
  if (typeof window === "undefined") return true
  return window.innerWidth <= BREAKPOINT_MOBILE
}

export function isTablet() {
  if (typeof window === "undefined") return true
  return window.innerWidth <= BREAKPOINT_TABLET
}

export function useBodyClass(className) {
  useEffect(() => {
    if (!className) return
    if (typeof document === "undefined") return
    document.body.classList.add(className)
    return () => document.body.classList.remove(className)
  }, [className])
}

export async function progressFetch(url, updateCallback) {
  let response = await fetch(url)
  const reader = response.body.getReader()
  const totalBytes = +response.headers.get("Content-Length")
  let receivedBytes = 0
  let chunks = []
  while (true) {
    const { done, value } = await reader.read()
    if (done) break
    chunks.push(value)
    receivedBytes += value.length
    const progress = receivedBytes / totalBytes
    if (typeof updateCallback === "function")
      updateCallback({ receivedBytes, totalBytes, progress })
  }
  let chunksAll = new Uint8Array(receivedBytes)
  let position = 0
  for (let chunk of chunks) {
    chunksAll.set(chunk, position)
    position += chunk.length
  }
  let resultStr = new TextDecoder("utf-8").decode(chunksAll)
  return resultStr
}

export class WebWorker {
  constructor(worker) {
    const code = worker.toString()
    const blob = new Blob(["(" + code + ")()"])
    return new Worker(URL.createObjectURL(blob))
  }
}

export function navigatorLang() {
  return typeof navigator === "object"
    ? (navigator.language || navigator.userLanguage).split("-")[0]
    : null
}

/*
function compatibleLang(langIdRaw) {
  if (typeof langIdRaw !== "string") return false
  const langId = langIdRaw.toLowerCase()
  return langs.find(l => l.id === langId) ? langId : false
}
*/