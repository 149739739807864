import Papa from "papaparse"
// import { progressFetch } from "../../modules/utils"
import { Station } from "./station-manager"

const RESERVED_COLUMN_NAMES = [
  "region",
  "okrug",
  "uik",
  "tik",
  "tikId",
  "voters",
  "totalVotes",
  "validVotes",
  "invalidVotes",
  "allVotes",
  "name",
  "id",
  "parent_id",
  "lat",
  "lon",
  "level",
  "code",
]

function isMetaUrl(url = "") {
  return url.match(/.meta.txt$/)
}

class ResultLoader {
  constructor(datasets, stationManager, candidateManager, groupBy) {
    this.datasets = datasets
    if (typeof window !== "undefined") window.resultLoader = this
    this.stationManager = stationManager
    this.candidateManager = candidateManager
    this.groupBy = groupBy
    this.groupStationsBy = this.stationManager.stationsLevel.groupBy
  }

  async loadResults(setLoadProgress, setCalcProgress) {
    // return true
    return new Promise((resolve) => {
      let processedUrls = 0
      const ts = Date.now()
      let dateUpdated = ""
      this.datasets.forEach(async (dataset) => {
        processedUrls++
        let resultUrl = dataset.results

        if (!resultUrl || this.stationManager.combined) {
          const { dateUpdated } = dataset
          setLoadProgress(1)
          setCalcProgress(1)
          resolve(dateUpdated)
          return
        }

        if (isMetaUrl(resultUrl)) {
          const path = resultUrl.replace("results.meta.txt", "")
          const metaCsv = await fetch(resultUrl + `?_ts=${ts}`).then((r) =>
            r.text()
          )
          const rows = Papa.parse(metaCsv, {
            header: false,
            delimiter: ";",
          }).data //
          const filteredRows = rows.filter((r) => r.length > 1)
          const lastRow = filteredRows[filteredRows.length - 1]
          dateUpdated = new Date(lastRow[1] * 1)
          // console.log("DATE UPDATED", dateUpdated, dataset.dateUpdated)
          resultUrl = `${path}${lastRow[0]}`
        }

        /* const updateCallback = ({ progress }) => {
          setLoadProgress(progress)
        } */

        // const resultStr = await progressFetch(resultUrl, updateCallback)
        const resultStr = await fetch(resultUrl, {
          // cache: "force-cache",
        }).then((r) => r.text())

        setLoadProgress(1)
        const results = await Papa.parse(resultStr, { header: true }).data // , delimiter: ";"

        if (!resultUrl) return

        const max = results.length
        for (let i = 0; i < max; i++) {
          const result = results[i]
          if (result.name) this.addToStation(result, dataset.id)
          // await (new Promise((res,rej) => setTimeout(res, 10)))
          if (i % 10000 === 0) {
            // console.log("another 5k")
            const progress = i / max
            setCalcProgress(progress)
          }
        }
        setCalcProgress(1)
        if (processedUrls === this.datasets.length)
          setTimeout(() => resolve(dateUpdated), 100) // finish
      })
    })
  }

  addStationResultToHigherLevels(result, dataset, parents = []) {
    parents.forEach((parent) => {
      const groupBy = parent.level.groupBy
      if (!parent.results) parent.results = {}
      if (!parent.results[dataset])
        parent.results[dataset] = new SummarizedResult(
          result,
          this.candidateManager,
          groupBy
        )
      else parent.results[dataset].addResults(result, this.groupBy)
    })
  }

  addToStation(r, dsId) {
    const stationUid = this.stationManager.getUid(r)
    let station = this.stationManager.getStation(stationUid) // unique id
    // console.log(stationUid)
    if (!window.notFound) window.notFound = []
    if (!station) {
      window.notFound.push(r)
      // fallback add to region
      const regKey = r.region
      const regionLevelId = this.stationManager.stationsLevelId - 2
      const region = this.stationManager.higherEntities.find(
        (e) => e.regKey === regKey && e.level.id === regionLevelId
      )
      if (!region) {
        console.log("couldnt find upper level entity") // , r.region
        return
      }

      const stationUid = this.stationManager.getUid(r)
      station = new Station(
        stationUid,
        r,
        this.stationManager.stationsLevel,
        false,
        this.stationManager
      )
      region.addChild(station)

      /* const parents = [region, ...region.parents]
      const stationResult = this.createNewResult(r)
      if (!stationResult) return
      this.addStationResultToHigherLevels(r, dsId, parents) */

      //  return
      /*
      // if there is no station with that UIK number, simply add results to higher level TIK
      const tik = this.stationManager.getTikById(result.tikId);
      if (tik) {
        const region = tik.region;
        const country = tik.country;
        const levels = [tik, region, country];
        this.addStationResultToHigherLevels(result, dataset, levels);
      }
      
      // backup solution: if there is even no TIK, add result to general/country result
      else {
        console.log('having troubles to find matching station/district ');
        console.log(result);
        this.stationManager.countries[0].results[dataset].addResults(result);
      }
      */
    }
    //} else {
    /*
      const tik = station.tik;
      // const region = station.region;
      // const country = station.country; 

      // const levels = Object.keys(station.parents).map(key => station[key]);
      
      // if station has no coordinates take those of higher level TIK
      if ((!station.lat || !station.lon) && (tik.lat && tik.lon)) {
        station.lat = tik.lat;
        station.lon = tik.lon;
      }
      */

    station.addResult(dsId, r)
    // }
  }

  createNewResult(r) {
    const totalVotes =
      parseInt(r.allVotes) ||
      parseInt(r.totalVotes) ||
      parseInt(r.validVotes) + parseInt(r.invalidVotes)
    if (!totalVotes) return
    return new Result(r, this.candidateManager, this.groupStationsBy)
  }
}

export default ResultLoader

export class Result {
  // TODO: groupBy
  constructor(r, candidateManager, groupBy) {
    this.voters = r ? parseInt(r.voters) : 0
    this.totalVotes = r
      ? parseInt(r.allVotes) ||
        parseInt(r.totalVotes) ||
        parseInt(r.validVotes) + parseInt(r.invalidVotes)
      : 0
    this.candidates = []
    this.candidateManager = candidateManager
    if (r) this.addAbstractions(r, groupBy)
  }
  addAbstractions(r, groupBy = "candidates") {
    for (let attr in r) {
      if (!RESERVED_COLUMN_NAMES.includes(attr)) {
        const votes = r[attr]
        if (votes) {
          const [name, firstName, patronymic] = attr.split(" ")
          const candidate = this.candidateManager.getOrAdd({
            name,
            firstName,
            patronymic,
          })
          this.candidates.push(new CandidateResult(candidate, votes, this))
          /* if (groupBy === "candidates") {
            const existingCandidateResult = this.getCandidateResult(candidate)
            if (existingCandidateResult) existingCandidateResult.addVotes(votes)
            else
              this.candidates.push(new CandidateResult(candidate, votes, this))
          } else if (groupBy === "parties") {
            const party = candidate.party
            // console.log("BEF", candidate)
            const existingPartyResult = this.getCandidateResult(party)
            if (existingPartyResult) existingPartyResult.addVotes(votes)
            else this.candidates.push(new PartyResult(party, votes, this))
          }*/
        }
      }
    }
  }

  getCandidateResult(candidate) {
    // console.log("CAND", candidate, this)
    return this.candidates.find((c) => c.candidate.uid === candidate.uid)
  }

  get turnout() {
    return Math.round((this.totalVotes / this.voters) * 1000) / 10
  }
  get exactTurnout() {
    return (this.totalVotes / this.voters) * 100
  }
  get winner() {
    return this.sortedCandidates[0]
  }
  get winnerId() {
    let result = ""
    try {
      result = this.winner.candidate.id // id
    } catch (e) {
      console.log(e, this)
    }
    return result
  }
  get winnerPartyName() {
    return this.winner.candidate.party ? this.winner.candidate.party.uid : ""
  }
  get winnerVotes() {
    return this.winner.votes
  }
  get winnerGap() {
    return Math.round((this.winner.share * 1 - this.second.share * 1) * 10) / 10
  }
  get winnerColor() {
    return this.winner.candidate.color
  }
  get second() {
    return this.sortedCandidates.length > 1 ? this.sortedCandidates[1] : {}
  }
  get sortedCandidates() {
    return this.candidates.sort((a, b) => b.votes - a.votes)
  }
}

export class SummarizedResult extends Result {
  addResults(r, groupBy, isParsedResult = false) {
    this.voters += parseInt(r.voters) || 0
    this.totalVotes +=
      parseInt(r.allVotes) ||
      parseInt(r.totalVotes) ||
      parseInt(r.validVotes) + parseInt(r.invalidVotes)
    if (!isParsedResult) {
      this.addAbstractions(r, groupBy)
    } else {
      ;(r.candidates || []).forEach(({ candidate, votes }) => {
        this.addCandidateResult(candidate, votes, this)
      })
    }
  }
  addCandidateResult(candidate, votes) {
    const existingCandidateResult = this.getCandidateResult(candidate)
    if (existingCandidateResult) existingCandidateResult.addVotes(votes)
    else this.candidates.push(new CandidateResult(candidate, votes, this))
  }
  // TODO: groupBy
  addAbstractions(r, groupBy = "candidates") {
    for (let attr in r) {
      if (!RESERVED_COLUMN_NAMES.includes(attr)) {
        const votes = r[attr]
        if (votes) {
          const [name, firstName, patronymic] = attr.split(" ")
          const candidate = this.candidateManager.getOrAdd({
            name,
            firstName,
            patronymic,
          })
          this.addCandidateResult(candidate, votes, this)
        }
      }
    }
  }

  addDateUpdated(ts) {
    this.dateUpdated = ts
  }
}

export class CandidateResult {
  constructor(candidate, votes, overallResult) {
    const parsedVotes = parseInt(votes)
    this.candidate = candidate
    this.votes = parsedVotes
    this.overallResult = overallResult
  }
  get share() {
    return Math.round((this.votes / this.overallResult.totalVotes) * 1000) / 10
  }
  get exactShare() {
    return (this.votes / this.overallResult.totalVotes) * 100
  }
  addVotes(votes) {
    this.votes += parseInt(votes)
  }
}

// class PartyResult extends CandidateResult {}
