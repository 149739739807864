import { useMemo, useEffect } from "react"
import { navigate } from "gatsby"
import { getDsPath } from "../../../elections-meta-data"

function usePath(langId, currElection, currDs, currEntity) {
  const path = useMemo(
    () => getDsPath(langId, currElection, currDs),
    [langId, currElection, currDs]
  )

  const search = useMemo(() => getEntitySearch(currEntity), [currEntity])

  useEffect(() => {
    // UPDATE URL
    navigate(`/${path}${search}`)
  }, [path, search])

  return [path]
}

export default usePath

function getEntitySearch(currEntity) {
  const def = typeof window !== "undefined" ? window.location.search : ""
  if (!currEntity) return def
  const type = encodeURIComponent(currEntity.level.name.slice(0, 1))
  const value = encodeURIComponent(currEntity.uidLong)
  return currEntity.level.id === 0 ? "" : `?${type}=${value}`
}
