import React, { useState, useContext, useMemo } from "react" // useEffect
import Zoom from "@mui/material/Zoom"
import Tooltip from "@mui/material/Tooltip"
// import ShareDialog from "../share-dialog"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnglesDown, faAnglesUp } from "@fortawesome/free-solid-svg-icons"
import { transcribe } from "transkribator"
import t from "../../modules/translations"
import "./result-box.scss"
import LangContext from "../../contexts/lang"

// todo: to locale string
// const BASE_URL = "https://elections.dekoder.org"

const CAND_ROW_HEIGHT = 50

const ResultBox = ({
  candidates, // need for re-render @ candidateClick updates
  currDs,
  currEntity,
  sourceLang,
  datasets,
  onCandidateClick,
  currElection,
  customFilterComp,
  allCands,
  updTrigger,
}) => {
  const lang = useContext(LangContext)
  const [showAll, setShowAll] = useState(false)
  // const [shareDialogOpen, setShareDialogOpen] = useState(false)

  const s = currEntity
  const isCountry = s.country === s
  const dsId = currDs.id
  const notFirstDs = Object.keys(s.results).length > 1 && dsId > 0
  // const r = s.results[dsId]
  const r = useMemo(() => {
    ;[].push(updTrigger)
    // console.log("UPDDD", currEntity.getResult(dsId))
    return currEntity.getResult(dsId)
  }, [currEntity, dsId, updTrigger])
  // const rUnfiltered = s.unfilteredResults[dsId]
  const rFirst = s.getResult(0)
  if (!r) return null // @todo: show result box even if there are no results
  let calculatedCountingStatus = ""
  if (s.stations) {
    const stationsWithResults = s.stations
      .filter((st) => st.results)
      .filter((st) => st.results[dsId]).length
    const stationsTotal = currDs.totalStations || s.stations.length
    calculatedCountingStatus = (
      Math.round((stationsWithResults / stationsTotal) * 1000) / 10
    ).toLocaleString(lang.locale)
  }

  return (
    <div className="result-box">
      <div className="result-box-title">
        <Tooltip
          title={
            s.address &&
            ["de", "en"].includes(lang.id) &&
            ["ru", "uk"].includes(sourceLang)
              ? transcribe(s.address, { to: lang.id, from: sourceLang })
              : ""
          }
        >
          <span className="result-title-span">
            {t(
              `${currElection.stationPrefix || ""}${s.displayName}`,
              lang.id,
              sourceLang
            )}
          </span>
        </Tooltip>
      </div>
      {customFilterComp}
      {!!r && (
        <>
          <div className="result-box-table">
            <div className="result-table-row">
              <div className="result-table-cell indicator-cell">
                {t("Turnout", lang.id)}:
              </div>
              <div className="result-table-cell value-cell">
                <strong>{r.turnout.toLocaleString(lang.locale)}&nbsp;%</strong>
              </div>
              <div className="result-table-cell deviation-cell">
                {notFirstDs
                  ? calcDev(r.turnout, rFirst.turnout, lang.locale)
                  : ""}
              </div>
              <div className="result-table-cell share-button-cell" rowSpan="3">
                {/*<FontAwesomeIcon icon={faShare} onClick={() => this.openShareDialog()} /> */}
              </div>
            </div>
            <div className="result-table-row">
              <div className="result-table-cell indicator-cell">
                {t("Votes", lang.id)}:
              </div>
              <div className="result-table-cell value-cell">
                {r.totalVotes.toLocaleString(lang.locale)} *
              </div>
              <div className="result-table-cell deviation-cell">
                {notFirstDs &&
                  calcDev(r.totalVotes, rFirst.totalVotes, lang.locale)}
              </div>
            </div>
            {isCountry && (
              <div className="result-table-row">
                <div className="result-table-cell">
                  {t("Counting status", lang.id)}:{" "}
                </div>
                <div className="result-table-cell">
                  {currDs.countingStatus || calculatedCountingStatus} % *
                </div>
              </div>
            )}
          </div>
          <div
            className="result-box-table result-box-candidate-table"
            style={{
              position: "relative",
              minHeight: showAll
                ? r.candidates.length * (CAND_ROW_HEIGHT + 5) + "px"
                : Math.min(r.candidates.length, 5) * (CAND_ROW_HEIGHT + 5) +
                  "px",
            }}
          >
            {
              // r.candidates
              //rUnfiltered.candidates // use unfiltered to keep order, otherwise transition animation will break
              allCands // stays constant
                .map((__cand, index) => {
                  const cr = r.candidates.find(
                    (c) => c.candidate.uid === __cand.uid
                  )
                  if (!cr) return null
                  // candidate result
                  const sortedCr = r.sortedCandidates.find(
                    (_cr) => _cr.candidate.uid === cr.candidate.uid
                  )
                  const orderId = r.sortedCandidates.indexOf(sortedCr)
                  // TODO if (!showAll && index >= 5) return ""
                  const fullName = cr.candidate.firstName
                    ? `${cr.candidate.firstName} ${cr.candidate.name}`
                    : cr.candidate.name
                  const crFirst = notFirstDs
                    ? rFirst.sortedCandidates.find(
                        (c) => c.candidate.uid === cr.candidate.uid
                      )
                    : {}
                  const tooltipText = (
                    <div className="result-tooltip">
                      <strong>{t(fullName, lang.id, sourceLang)}</strong>
                      <br />
                      <table className="result-tooltip-table">
                        <tbody>
                          <tr>
                            <td>{t(currDs.name, lang.id)}: </td>
                            <td>{cr.votes.toLocaleString(lang.locale)}</td>
                            <td>({cr.share.toLocaleString(lang.locale)} %)</td>
                          </tr>
                          {notFirstDs && (
                            <tr>
                              <td>{t(datasets[0].name, lang.id)}: </td>
                              <td>
                                {crFirst.votes.toLocaleString(lang.locale)}
                              </td>
                              <td>
                                ({crFirst.share.toLocaleString(lang.locale)} %)
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {cr.candidate.selected
                        ? t("click to unfilter", lang.id)
                        : t("click to filter", lang.id)}
                    </div>
                  )
                  const isActive = cr.candidate.active // (selectedCandidates || []).includes(cr.candidate.uid)
                  return (
                    <Tooltip
                      key={cr.candidate.uid}
                      TransitionComponent={Zoom}
                      placement="bottom-start"
                      title={tooltipText}
                    >
                      <div // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
                        className={
                          "result-table-row candidate-row" +
                          (isActive ? " active" : " inactive")
                        }
                        style={{
                          position: "absolute",
                          width: "100%",
                          transform: `translateY(${
                            orderId * (CAND_ROW_HEIGHT + 5)
                          }px)`,
                          top: 0,
                          left: 0,
                        }}
                        onClick={(e) =>
                          onCandidateClick(cr.candidate, e.altKey)
                        }
                      >
                        <div className="result-table-cell image-cell">
                          {cr.candidate.image ? (
                            <div className="crop-container">
                              <img
                                className="candidate-image"
                                alt={cr.candidate.name}
                                src={cr.candidate.image}
                              />
                            </div>
                          ) : (
                            cr.candidate.emoji || ""
                          )}
                        </div>
                        <div className="result-table-cell name-cell indicator-cell">
                          {t(cr.candidate.name, lang.id, sourceLang)}
                        </div>
                        <div className="result-table-cell result-cell">
                          <div
                            className="result-bar"
                            style={{
                              backgroundColor: cr.candidate.color,
                              width: cr.share + "%",
                            }}
                          >
                            <span>
                              <strong>
                                {cr.share.toLocaleString(lang.locale)}&nbsp;%
                              </strong>
                            </span>
                          </div>
                          {!!notFirstDs &&
                            calcDev(cr.share, crFirst.share, lang.locale)}
                        </div>
                      </div>
                    </Tooltip>
                  )
                })
            }
          </div>
          {r.sortedCandidates.length > 5 && (
            <Tooltip
              title={showAll ? t("show less", lang.id) : t("show all", lang.id)}
            >
              <span>
                <ShowMoreHideButton
                  showAll={showAll}
                  onClick={() => setShowAll((sa) => !sa)}
                />
              </span>
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(ResultBox)

/* <ShareDialog
            open={shareDialogOpen}
            onClose={() => setShareDialogOpen(false)}
            url={""} // this.state.shareableUrl
          /> */

const ShowMoreHideButton = ({ onClick, showAll }) => (
  <button
    className="show-more-hide-button"
    onClick={onClick}
    onKeyDown={onClick}
  >
    <FontAwesomeIcon icon={showAll ? faAnglesUp : faAnglesDown} />
  </button>
)

function calcDev(val1, val2, locale = "en-US") {
  const dev = val1 - val2
  return (
    <span className={dev < 0 ? "deviation negative" : "deviation positive"}>
      {dev.toLocaleString(locale)}
    </span>
  )
}
