import React, { useContext, useState, useEffect } from "react"
import Select from "react-select"
import t from "../../modules/translations"
import Slider from "@mui/material/Slider"
import "./filter-selector.scss"
import LangContext from "../../contexts/lang"
import { TURNOUT, WINNER_GAP } from "../map-controller/geojson"

const FilterSelector = ({ onFilterChange, selectedFilter }) => {
  const lang = useContext(LangContext)
  const [range, setRange] = useState({ min: 0, max: 100 })
  function onRangeChange(ev, newRange) {
    const [min, max] = newRange
    if (min >= 0 && max <= 100) setRange({ min, max })
  }
  useEffect(() => {
    onFilterChange(selectedFilter.name, range)
  }, [range, selectedFilter.name, onFilterChange])

  const filters = [
    {
      value: TURNOUT,
      label: t("Turnout", lang.id),
    },
    {
      value: WINNER_GAP,
      label: t("Winner-Gap", lang.id),
    },
  ]
  const selectedOption = filters.find((f) => f.value === selectedFilter.name)
  return (
    <div className="filter-selector-wrapper">
      <Select
        className="filter-selector-select"
        options={filters}
        isClearable={true}
        value={selectedOption}
        placeholder={t("Filter", lang.id) + " ..."}
        onChange={(e) => onFilterChange(e ? e.value : "", range)}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
      />
      {!!selectedFilter.name && (
        <Slider
          className="input-range"
          getAriaLabel={() => "Filter range"}
          getAriaValueText={(value) => `${value} %`}
          value={[range.min, range.max]}
          valueLabelDisplay="auto"
          onChange={onRangeChange}

          // draggableTrack
          // maxValue={100}
          // minValue={0}
          // formatLabel={(value) => `${value} %`}
          // onChangeComplete={onRangeChangeComplete}
        />
      )}
    </div>
  )
}

export default FilterSelector
