import { useMemo } from "react"

export function useFeatureFilter(geoJson, filterFunc) {
  return useMemo(
    () => ({
      ...geoJson,
      features: [...geoJson.features.filter(filterFunc)],
    }),
    [geoJson, filterFunc]
  )
}
