import React, { useContext, useMemo } from "react"
import useDatasetFilter from "../dataset-selector/dataset-filter"
import DatasetSelector from "../dataset-selector"
import LocationSelector from "../location-selector"
import ResultBox from "../result-box"
import Footer from "../footer"
import "./info-panel.scss"
import LangContext from "../../contexts/lang"
import { LocationBreadCrumbs, DatasetBreadCrumbs } from "../bread-crumbs"
import { InfoPanelWidget } from "./info-panel-widget"
import Histogram from "../histogram/histogram"
import t from "../../modules/translations"
import ResultPreview from "../result-preview/result-preview"
import ColorLegend from "../color-legend/color-legend"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBraille,
  faChartLine,
  faChartPie,
  faInfoCircle,
  faList,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons"
import ScatterPlot from "../scatter-plot"
import { elections } from "../../../elections-meta-data"

const HISTOGRAM_LINKS = {
  de: "https://www.dekoder.org/de/article/schpilkin-verfassungsaenderung-abstimmung-anomalien",
  en: "https://www.dekoder.org/de/article/schpilkin-verfassungsaenderung-abstimmung-anomalien",
  ru: "https://www.proekt.media/opinion/shpilkin-falsifikatsiya-golosovaniya/",
  // "https://meduza.io/cards/vizhu-mnogo-grafikov-o-falsifikatsii-na-vyborah-chto-oni-znachat",
  uk: "https://www.proekt.media/opinion/shpilkin-falsifikatsiya-golosovaniya/",
}

const InfoPanel = (props) => {
  const { electionProps, electionData, currEntity, customFilterComp } = props
  const {
    statistics,
    interactions,
    hoverTurnout,
    allCands,
    updTrigger,
    collapsed,
    setInfoPanelOpen,
  } = props
  const lang = useContext(LangContext)
  const [currElection, currDs] = electionProps
  const [, candidates, dateUpdated] = electionData
  const [onCandidateClick, onLocationClick] = interactions
  const [colorStops, histogramData, scatterPlotData, turnout] = statistics
  const { sourceLang, sourceName, sourceUrl } = currElection || {}
  const { datasets, colorBy } = currElection || {}
  const { countingStatus } = currDs || {}
  const [datasetFilter, setDatasetFilter] = useDatasetFilter(electionProps)

  const results = useMemo(() => {
    const s = currEntity || {}
    const otherStationsHere = s.otherStationsHere || []
    return [s, ...otherStationsHere]
  }, [currEntity])

  return (
    <div className={`info-panel ${collapsed ? "collapsed" : "open"}`}>
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        className="info-panel-inner"
        onClick={collapsed ? () => setInfoPanelOpen(true) : null}
        onKeyDown={collapsed ? () => setInfoPanelOpen(true) : null}
      >
        <InfoPanelWidget
          gray
          initiallyExpanded={!currElection}
          icon={faList}
          name="data-selector"
        >
          <DatasetBreadCrumbs
            lang={lang}
            sourceLang={sourceLang}
            datasetFilter={datasetFilter}
            setDatasetFilter={setDatasetFilter}
          />
          <DatasetSelector
            elections={elections}
            currElection={currElection}
            currDs={currDs}
            datasetFilter={datasetFilter}
            setDatasetFilter={setDatasetFilter}
          />
        </InfoPanelWidget>
        <InfoPanelWidget icon={faLocationDot} name="location-selector">
          <LocationBreadCrumbs
            currEntity={currEntity}
            sourceLang={sourceLang}
            onLocationClick={onLocationClick}
          />
          {!!currEntity && (
            <LocationSelector
              onLocationClick={onLocationClick}
              currEntity={currEntity}
              sourceLang={sourceLang}
            />
          )}
        </InfoPanelWidget>
        <InfoPanelWidget
          initiallyExpanded={colorBy !== "turnout"}
          icon={faChartPie}
          name="results"
        >
          <div className="result-widget-outer-wrapper">
            {results
              .filter((st) => st.results)
              .map((st, i) => (
                <div className="result-block-wrapper" key={i}>
                  <ResultPreview currDs={currDs} currEntity={st} />
                  <ResultBox
                    currDs={currDs}
                    countingStatus={countingStatus}
                    currEntity={st}
                    sourceLang={sourceLang}
                    datasets={datasets}
                    onCandidateClick={onCandidateClick}
                    currElection={currElection}
                    candidates={candidates}
                    customFilterComp={customFilterComp}
                    allCands={allCands}
                    updTrigger={updTrigger}
                  />
                </div>
              ))}
            <Footer
              date={dateUpdated || currDs ? currDs.dateUpdated : null}
              sourceName={sourceName}
              sourceUrl={sourceUrl}
            />
          </div>
        </InfoPanelWidget>
        <InfoPanelWidget
          initiallyExpanded={colorBy === "turnout"}
          icon={faChartLine}
          name="histogram"
        >
          <div className="info-panel-widget-title">
            {t("histogram", lang.id)}{" "}
            <a
              href={HISTOGRAM_LINKS[lang.id]}
              target="_blank"
              rel="norel noreferrer"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </div>
          <Histogram data={histogramData} turnout={turnout} />
          <ColorLegend
            colorStops={colorStops}
            turnout={turnout}
            hoverTurnout={hoverTurnout}
          />
        </InfoPanelWidget>
        <InfoPanelWidget
          initiallyExpanded={false}
          icon={faBraille}
          name="scatter-plot"
        >
          <div className="info-panel-widget-title">
            {t("scatter plot", lang.id)}{" "}
            <a
              href={HISTOGRAM_LINKS[lang.id]}
              target="_blank"
              rel="norel noreferrer"
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </a>
          </div>
          <ScatterPlot data={scatterPlotData} />
        </InfoPanelWidget>
      </div>
    </div>
  )
}

export default React.memo(InfoPanel)
