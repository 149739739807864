import React, { useCallback, useMemo } from "react"
import useGeoJson from "./geojson.js"
// import MapboxMap from "../map-mapbox"
// import usePaint from "./_paint.js"
// import useFilters from "./_filters.js"
import useBounds from "./bounds.js"
import { DeckGlMap } from "../map-deckgl/deckgl-map"
import { useFeatureFilter } from "./feature-filter"

import { WINNER_ID, WINNER_PARTY_NAME } from "./geojson" // TOTAL_VOTES

// const DEFAULT_COLOR_BY = "winner"
const DEFAULT_GROUP_BY = "candidates"

const MapController = (props) => {
  const { onPointClick, allCands } = props // hoverInteractions
  const { electionProps, electionData, currEntity } = props
  const { selectedFilter, colorStops } = props
  const [currElection = {}, currDs = {}] = electionProps
  const [stations, candidates] = electionData

  const geoJson = useGeoJson(stations, currElection?.datasets)

  // NEW deck.gl

  const groupBy = currElection?.groupBy || DEFAULT_GROUP_BY
  const dsId = currDs?.id

  const regFilter = useCallback(
    (f) =>
      currEntity.typeLong === "station" ||
      f.properties[currEntity.type] === currEntity.uid,
    [currEntity]
  )

  const candFilter = useMemo(() => {
    const candFilterPropName =
      groupBy === "parties" ? WINNER_PARTY_NAME : WINNER_ID
    const inactiveCandIds = candidates.filter((c) => !c.active).map((c) => c.id)
    // console.log(candFilterPropName, inactiveCandIds)
    return (f) =>
      !inactiveCandIds.includes(f.properties[`${candFilterPropName}${dsId}`])
  }, [candidates, groupBy, dsId])

  const regFilteredGeoJson = useFeatureFilter(geoJson, regFilter)
  const candFilteredGeoJson = useFeatureFilter(regFilteredGeoJson, candFilter)
  const bounds = useBounds(currEntity, regFilteredGeoJson)

  return (
    <DeckGlMap
      geoJson={candFilteredGeoJson}
      onPointClick={onPointClick}
      allCands={allCands}
      bounds={bounds}
      selectedFilter={selectedFilter}
      dsId={dsId}
      colorBy={currElection?.colorBy}
      colorStops={colorStops}
      selected={currEntity.typeLong === "station" ? currEntity : null}
    />
  )
}

export default React.memo(MapController)

/**
 * 
 * 
 const paint = usePaint(
    currDs?.id,
    colorStops,
    currElection?.colorBy || DEFAULT_COLOR_BY,
    allCands
  )
  const [filters] = useFilters(
    currDs?.id,
    candidates,
    currEntity,
    selectedFilter,
    currElection?.groupBy || DEFAULT_GROUP_BY
  ) 
  

    <Map
      geoJson={geoJson}
      paint={paint}
      filters={filters}
      bounds={bounds}
      onPointClick={onPointClick}
      hoverInteractions={hoverInteractions}
      colorStops={colorStops}
      currEntity={currEntity}
    />  
  
  */
