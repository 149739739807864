import React, { useContext } from "react"
import LangContext from "../../contexts/lang"
import t from "../../modules/translations"
import "./bread-crumbs.scss"

const BreadCrumb = ({ onClick, value, type, lang, sourceLang }) => {
  return (
    <span className="bread-crumb">
      <button onClick={() => onClick(value, type)}>
        {t(value, lang.id, sourceLang)}
      </button>
    </span>
  )
}

export const LocationBreadCrumbs = ({
  currEntity,
  onLocationClick,
  sourceLang,
}) => {
  const lang = useContext(LangContext)
  if (!currEntity) return null
  const parents = [...currEntity.parents, currEntity].sort(
    (a, b) => a.level.id - b.level.id
  )
  return (
    <div className="bread-crumbs">
      {parents.map((p, i) => (
        <BreadCrumb
          key={i}
          onClick={() => onLocationClick(p.uid, p.level.id)}
          lang={lang}
          sourceLang={sourceLang}
          value={p.displayName}
        />
      ))}
    </div>
  )
}

export const DatasetBreadCrumbs = ({
  datasetFilter,
  setDatasetFilter,
  lang,
  sourceLang,
}) => (
  <div className="bread-crumbs">
    <BreadCrumb
      onClick={setDatasetFilter}
      lang={lang}
      sourceLang={sourceLang}
      value="Countries"
      type="root"
    />
    {!!datasetFilter.country && (
      <BreadCrumb
        onClick={setDatasetFilter}
        lang={lang}
        sourceLang={sourceLang}
        value={datasetFilter.country}
        type="country"
      />
    )}
    {!!datasetFilter.type && (
      <BreadCrumb
        onClick={setDatasetFilter}
        lang={lang}
        sourceLang={sourceLang}
        value={datasetFilter.type}
        type="type"
      />
    )}
    {!!datasetFilter.year && (
      <BreadCrumb
        onClick={setDatasetFilter}
        lang={lang}
        sourceLang={sourceLang}
        value={datasetFilter.year}
        type="year"
      />
    )}
  </div>
)
