import React, { useContext, useCallback } from "react"
import Select from "react-select"
import LangContext from "../../contexts/lang"
import { useAutoselect } from "./autoselect"
import { useOptions } from "./options"

const DatasetSelector = ({
  setDatasetFilter,
  currElection = {},
  currDs,
  datasetFilter,
  elections,
}) => {
  const onChange = useCallback(
    (e) => {
      setDatasetFilter(e ? e.value : false, e ? e.type : false)
    },
    [setDatasetFilter]
  )
  const lang = useContext(LangContext)

  const [options, selectedOption, placeholder] = useOptions(
    datasetFilter,
    elections,
    currElection,
    currDs,
    lang
  )

  useAutoselect(options, onChange, selectedOption)

  return (
    <div className="info-panel-selector info-panel-dataset-selector">
      <Select
        options={options}
        value={selectedOption?.value !== undefined ? selectedOption : null} // selectedOption
        onChange={onChange}
        placeholder={`Select ${placeholder} ...`}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
      />
    </div>
  )
}

export default React.memo(DatasetSelector)
