import React, { useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import "./info-panel-widget.scss"
import { IconWrapper, WidgetWrapper } from "./info-panel-widget.style"
import { IconProp } from "@fortawesome/fontawesome-svg-core"

interface InfoPanelWidgetProps {
  children: React.ReactNode
  initiallyExpanded?: boolean
  hidden?: boolean
  gray?: boolean
  icon?: IconProp
  name?: string
}

export const InfoPanelWidget = ({
  children,
  initiallyExpanded,
  hidden,
  gray,
  icon,
  name = "",
}: InfoPanelWidgetProps) => {
  const [expanded, setExpanded] = useState(!!initiallyExpanded)
  const toggleExpanded = () => setExpanded((e) => !e)
  useEffect(() => {
    setExpanded(!!initiallyExpanded)
  }, [initiallyExpanded])
  const className = `info-panel-widget
    ${name ? `info-panel-widget-${name}` : ""}
    ${expanded ? "expanded" : "collapsed"}
    ${hidden ? "hidden" : ""}
    ${gray ? "gray show-always" : ""}`
  return (
    <WidgetWrapper
      className={className}
      onClick={expanded ? undefined : toggleExpanded}
      onKeyDown={expanded ? undefined : toggleExpanded}
    >
      {!!icon && (
        <IconWrapper className="icon-wrapper">
          <FontAwesomeIcon icon={icon} />
        </IconWrapper>
      )}
      {children}
      <div className="expand-button-wrapper">
        <button
          className="expand-button"
          onClick={expanded ? toggleExpanded : undefined}
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </button>
      </div>
    </WidgetWrapper>
  )
}
