import dekoderLogo from "./src/images/logos/dekoder-white.svg"

export const siteLogos = [
  {
    src: dekoderLogo,
    url: {
      de: "https://www.dekoder.org/",
      en: "https://specials.dekoder.org/en",
      ru: "https://www.dekoder.org/ru",
      uk: "https://www.dekoder.org/ru",
    },
    scale: 0.8
  },
]

export { baseUrl } from "./meta-data-node"