import React, { useEffect } from "react"
import useMapbox from "./mapbox"
import useDataPlotter from "./data-plotter"
import useSelectionMarker from "./selection-marker"
import { isMobile } from "../../modules/utils"

import "./map.scss"

const SOURCE_NAME = "polling-stations-source"
const LAYER_NAME = "polling-stations-layer"

const MapboxMap = ({
  geoJson,
  paint,
  filters,
  bounds,
  onPointClick,
  hoverInteractions,
  colorStops,
  currEntity,
}) => {
  const [mapRef, map] = useMapbox()
  useDataPlotter(
    map,
    geoJson,
    SOURCE_NAME,
    LAYER_NAME,
    paint,
    onPointClick,
    hoverInteractions,
    colorStops
  )
  useFilters(map, filters, LAYER_NAME, colorStops)
  useBounds(map, bounds, colorStops)
  useSelectionMarker(map, currEntity)

  return <div className="map-container" ref={mapRef} />
}

export default MapboxMap

function useFilters(map, filters, layerName, colorStops) {
  useEffect(() => {
    if (!map) return
    if (!colorStops) return
    map.setFilter(layerName, filters)
  }, [map, filters, layerName, colorStops])
}

function useBounds(map, bounds, colorStops) {
  useEffect(() => {
    if (!map || !bounds) return
    if (!colorStops) return
    const padding = getPadding()
    // console.log("FIT BOUNDS", bounds, padding)
    map.setPadding(padding)
    map.fitBounds(bounds) // , { padding })
  }, [map, bounds, colorStops])
}

export function getPadding() {
  return isMobile()
    ? 10
    : {
        top: 10,
        left: 10,
        right: 500, // info-panel width
        bottom: 10,
      }
}
