import { useState, useEffect, useMemo, useCallback } from "react"
import CandidateManager from "./candidate-manager"
import StationManager from "./station-manager"
import ResultLoader from "./result-loader"
import useLoadingStatus from "../loading-progress-bar/loading-status"

export default function useElectionLoader(currElection, search, baseUrl) {
  const [loaded, setLoaded] = useState(false)
  const [currEntity, setCurrEntity] = useState(false)
  const [stations, setStations] = useState([])
  const [candidates, setCandidates] = useState([])
  const [dateUpdated, setDateUpdated] = useState()
  const [totalProgress, newTask] = useLoadingStatus(currElection)

  useEffect(() => {
    setCurrEntity(false)
    setLoaded(false)
  }, [currElection])

  const candidateManager = useMemo(() => {
    if (!currElection) return
    return new CandidateManager(currElection.candidates, baseUrl)
  }, [currElection, baseUrl])

  const stationManager = useMemo(() => {
    if (!currElection) return
    return new StationManager(currElection, candidateManager)
  }, [currElection, candidateManager])

  const highestEntity = useMemo(
    () => (loaded ? stationManager.highestEntity : null),
    [loaded, stationManager]
  )

  useEffect(() => {
    if (!search || !loaded) return
    const newEntity = getEntityFromSearch(search, stationManager)
    if (newEntity) setCurrEntity(newEntity)
  }, [search, stationManager, loaded])

  useEffect(() => {
    async function onElectionChange() {
      const setStationsProgress = newTask()
      const setResultsProgress = newTask()
      const setCalcProgress = newTask()
      await stationManager.loadStations(setStationsProgress)
      const resultLoader = new ResultLoader(
        currElection.datasets,
        stationManager,
        candidateManager,
        currElection.groupBy
      )
      const resultsUpdated = await resultLoader.loadResults(
        setResultsProgress,
        setCalcProgress
      )
      if (resultsUpdated) setDateUpdated(resultsUpdated)
      const preselectedEntity = getEntityFromSearch(
        window.location.search,
        stationManager
      )
      setStations(stationManager.stations)
      setCandidates(candidateManager[currElection.groupBy]) // candidates
      setCurrEntity(preselectedEntity || stationManager.highestEntity)
      setTimeout(() => setLoaded(true), 200)
    }
    if (!currElection) return
    onElectionChange()
  }, [candidateManager, currElection, stationManager, newTask])

  const onCandidateClick = useCallback(
    (candidate, altKey) => {
      candidate.selectUnselect(() => {}, altKey)
      setCandidates([...candidateManager[currElection.groupBy]])
    },
    [candidateManager, currElection]
  )

  const onLocationClick = useCallback(
    (uid, level) => {
      const newEntitiy = stationManager.getEntity(uid, level)
      // console.log(`ENTITY CLICK, ${uid}, ${level}`, newEntitiy)
      if (newEntitiy) setCurrEntity(newEntitiy)
    },
    [stationManager]
  )

  const entities = [currEntity, highestEntity]
  const loadingState = [loaded, totalProgress]
  const elData = [stations, candidates, dateUpdated]
  const interactions = [onCandidateClick, onLocationClick]

  return [entities, loadingState, elData, interactions]
}

function getEntityFromSearch(search, stationManager) {
  const $_GET = search
    .slice(1)
    .split("&")
    .reduce((acc, curr) => {
      const [key, value] = curr.split("=").map(decodeURIComponent)
      return { ...acc, [key]: value }
    }, {})
  const newEntity = Object.keys($_GET).reduce((acc, key) => {
    const uid = $_GET[key]
    const newEntity = stationManager.getEntityByLevelLetter(uid, key)
    return newEntity || acc
  }, null)
  // console.log("NEW GET", $_GET, newEntity)
  return newEntity
}
