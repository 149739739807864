import { useMemo } from "react"

export const TURNOUT = "to"
export const TOTAL_VOTES = "tv"
export const WINNER_GAP = "gap"
export const WINNER_ID = "win"
export const WINNER_PARTY_NAME = "part"
// export const WINNER_COLOR = "col"

export default function useGeoJson(stations, datasets) {
  const geoJson = useMemo(() => {
    const result = geoJsonFromStations(stations, datasets) || {}
    // console.log("GEOJSON", result, JSON.stringify(result).length)
    return result
  }, [stations, datasets])
  return geoJson
}

function geoJsonFromStations(stations, datasets) {
  const features = stations
    .filter((station) => station.lat && station.lon && station.results)
    // .filter(station => station.results[currDs.id])
    .map((station) => {
      const parentProps = station.parents.reduce((pAcc, parent) => {
        return {
          ...pAcc,
          [parent.type]: parent.uid, // parent.name
        }
      }, {})
      const dataProps = datasets.reduce((dAcc, ds) => {
        return {
          ...dAcc,
          [TURNOUT + ds.id]: station.getResult(ds.id)
            ? station.getResult(ds.id).turnout
            : 0,
          [WINNER_ID + ds.id]: station.getResult(ds.id)
            ? station.getResult(ds.id).winnerId
            : "",
          /*[WINNER_PARTY_NAME + ds.id]: station.results[ds.id]
            ? station.results[ds.id].winnerPartyName
            : "",
          ["winnerVotes" + ds.id]: station.results[ds.id]
            ? station.results[ds.id].winnerVotes
            : 0,*/
          [TOTAL_VOTES + ds.id]: station.getResult(ds.id)
            ? station.getResult(ds.id).totalVotes
            : 0,
          [WINNER_GAP + ds.id]: station.getResult(ds.id)
            ? station.getResult(ds.id).winnerGap
            : 0,
          /*[WINNER_COLOR + ds.id]: station.results[ds.id]
            ? station.results[ds.id].winnerColor
            : "",*/
        }
      }, {})
      let res = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [station.lon, station.lat],
        },
        properties: {
          // name: station.name,
          uid: station.uid,
          // isForeignStation: station.isForeignStation,
          ...parentProps,
          ...dataProps,
        },
      }
      if (station.isForeignStation) {
        res = {
          ...res,
          properties: {
            ...res.properties,
            isForeignStation: true,
          },
        }
      }
      return res
    })
  return {
    type: "FeatureCollection",
    features: features,
  }
}

/* function round(val) { // 3 decimals
  return Math.round(val * 1000) / 1000
} */
