import React, { useMemo, useState, useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilter } from "@fortawesome/free-solid-svg-icons"
import t from "../../modules/translations"

export function useCustomFilter(currEntity, currElection, lang, dsId) {
  const [customFilterName, setCustomFilterName] = useState(null)
  const [updTrigger, setUpdTrigger] = useState(0)

  useEffect(() => {
    setCustomFilterName(null)
  }, [currEntity])

  const customFilter = useMemo(
    () =>
      (currElection?.customFilters || []).find(
        (f) => f.name === customFilterName && f.condition(currEntity)
      ),
    [currElection, customFilterName, currEntity]
  )

  // console.log("CURR E", currEntity, customFilter, currElection.customFilters)

  useEffect(() => {
    if (!customFilter?.filter || !currEntity) return
    currEntity.setFilterFunc(customFilter.filter, dsId)
    setUpdTrigger((v) => v + 1)
    return () => {
      currEntity.setFilterFunc(undefined, undefined)
      setUpdTrigger((v) => v + 1)
    }
  }, [customFilter, currEntity, dsId])

  const filterBtns = (currElection?.customFilters || [])
    .filter((f) => f.condition(currEntity))
    .map((f, i) => {
      const isActive = f.name === customFilterName
      return (
        <button
          key={i}
          onClick={() => setCustomFilterName(isActive ? null : f.name)}
          className={isActive ? "active" : ""}
        >
          {t(f.name, lang.id)}
        </button>
      )
    })

  const comp = !!filterBtns.length && (
    <div className="result-box-custom-filter-wrapper">
      <div>
        <FontAwesomeIcon icon={faFilter} />
      </div>
      <button
        onClick={() => setCustomFilterName(null)}
        className={!customFilterName ? "active" : ""}
      >
        {t("all votes", lang.id)}
      </button>
      {filterBtns}
    </div>
  )

  return [currEntity, comp, updTrigger]
}

/* function cloneAndPatchEntity(orig, filterFunc, dsId) {
  const clone = Object.assign(Object.create(Object.getPrototypeOf(orig)), orig)
  clone.setFilterFunc(filterFunc, dsId)
  return clone
} */

/* const filteredEntity = useMemo(() => {
    if (!customFilter || !customFilter.condition(currEntity)) return currEntity
    else return cloneAndPatchEntity(currEntity, customFilter.filter, dsId)
  }, [currElection, customFilterName, currEntity, dsId])*/
