import React, { useContext } from "react"
import t from "../../modules/translations"
import "./footer.scss"
import LangContext from "../../contexts/lang"

const Footer = ({ date, sourceUrl, sourceName }) => {
  const lang = useContext(LangContext)
  const dateString = date ? hhmm(new Date(date)) : ""
  // console.log("FOOTER DS", date, dateString)
  return (
    <div className="footer">
      {!!dateString && (
        <div>
          * {t("Last update", lang.id)}: {dateString}
        </div>
      )}
      <div>
        {t("Source", lang.id) + ": "}
        <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
          {sourceName}
        </a>
      </div>
    </div>
  )
}

export default Footer

/*
      <div>* {t("The results are updated regularly", lang.id)}.</div> 
*/

function hhmm(date) {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const hour = date.getHours()
  const minute = date.getMinutes()
  const resultDate = [
    (day > 9 ? "" : "0") + day,
    (month > 9 ? "" : "0") + month,
    date.getFullYear(),
  ].join(".")
  const resultTime = [
    (hour > 9 ? "" : "0") + hour,
    (minute > 9 ? "" : "0") + minute,
  ].join(":")
  const offsetHours = (date.getTimezoneOffset() * -1) / 60
  const timezoneStr =
    offsetHours === 0
      ? `GMT`
      : offsetHours > 0
        ? `GMT+${offsetHours}`
        : `GMT-${offsetHours}`
  return `${resultDate} ${resultTime} (${timezoneStr})`
}
