export const region_dict_rev = {
  baikonur: "Город Байконур (Республика Казахстан)",
  foreign: "Территория за пределами РФ",
  altai_terr: "Алтайский край",
  amur: "Амурская область",
  arkhangelsk: "Архангельская область",
  astrakhan: "Астраханская область",
  belgorod: "Белгородская область",
  bryansk: "Брянская область",
  vladimir: "Владимирская область",
  volgograd: "Волгоградская область",
  vologod: "Вологодская область",
  voronezh: "Воронежская область",
  jewish_aut: "Еврейская автономная область",
  zabkray: "Забайкальский край",
  ivanovo: "Ивановская область",
  irkutsk: "Иркутская область",
  "kabardin-balkar": "Кабардино-Балкарская Республика",
  kaliningrad: "Калининградская область",
  kaluga: "Калужская область",
  kamchatka_krai: "Камчатский край",
  "karachaev-cherkess": "Карачаево-Черкесская Республика",
  kemerovo: "Кемеровская область - Кузбасс",
  kirov: "Кировская область",
  kostroma: "Костромская область",
  krasnodar: "Краснодарский край",
  krasnoyarsk: "Красноярский край",
  kurgan: "Курганская область",
  kursk: "Курская область",
  "leningrad-reg": "Ленинградская область",
  lipetsk: "Липецкая область",
  magadan: "Магаданская область",
  moscow_reg: "Московская область",
  murmansk: "Мурманская область",
  nenetsk: "Ненецкий автономный округ",
  nnov: "Нижегородская область",
  novgorod: "Новгородская область",
  novosibirsk: "Новосибирская область",
  omsk: "Омская область",
  orenburg: "Оренбургская область",
  orel: "Орловская область",
  penza: "Пензенская область",
  permkrai: "Пермский край",
  primorsk: "Приморский край",
  pskov: "Псковская область",
  adygei: "Республика Адыгея (Адыгея)",
  altai_rep: "Республика Алтай",
  bashkortostan: "Республика Башкортостан",
  buriat: "Республика Бурятия",
  dagestan: "Республика Дагестан",
  ingush: "Республика Ингушетия",
  kalmyk: "Республика Калмыкия",
  karel: "Республика Карелия",
  komi: "Республика Коми",
  crimea: "Республика Крым (occupied)",
  "mari-el": "Республика Марий Эл",
  mordov: "Республика Мордовия",
  yakut: "Республика Саха (Якутия)",
  "n_osset-alania": "Республика Северная Осетия - Алания",
  tatarstan: "Республика Татарстан (Татарстан)",
  tyva: "Республика Тыва",
  khakas: "Республика Хакасия",
  rostov: "Ростовская область",
  ryazan: "Рязанская область",
  samara: "Самарская область",
  saratov: "Саратовская область",
  sakhalin: "Сахалинская область",
  sverdlovsk: "Свердловская область",
  smolensk: "Смоленская область",
  stavropol: "Ставропольский край",
  tambov: "Тамбовская область",
  tver: "Тверская область",
  tomsk: "Томская область",
  tula: "Тульская область",
  tyumen: "Тюменская область",
  udmurt: "Удмуртская Республика",
  ulyanovsk: "Ульяновская область",
  khabarovsk: "Хабаровский край",
  "khantu-mansy": "Ханты-Мансийский автономный округ - Югра",
  chelyabinsk: "Челябинская область",
  chechen: "Чеченская Республика",
  chuvash: "Чувашская Республика - Чувашия",
  chukot: "Чукотский автономный округ",
  "yamal-nenetsk": "Ямало-Ненецкий автономный округ",
  yaroslavl: "Ярославская область",
  moscow_city: "город Москва",
  "st-petersburg": "город Санкт-Петербург",
  sevastopol: "город Севастополь (occupied)",
  //
  "jewish-aut": "Еврейская автономная область",
  "kamchatka-krai": "Камчатский край",
  "moscow-reg": "Московская область",
  "altai-rep": "Республика Алтай",
  "n-osset-alania": "Республика Северная Осетия - Алания",
  "moscow-city": "город Москва",
  "altai-terr": "Алтайский край",
  //
  donetsk: "Донецкая Народная Республика (occupied)",
  lugansk: "Луганская Народная Республика (occupied)",
  zaporozhye: "Запорожская область (occupied)",
  kherson: "Херсонская область (occupied)",
}
