import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import t from "../../modules/translations"
import LangContext from "../../contexts/lang"

const BASE_URL = "https://elections.dekoder.org"

const Seo = ({ socialImage, title, path }) => {
  const lang = useContext(LangContext)
  const image =
    typeof socialImage === "object" ? socialImage[lang.id] : socialImage || ""
  const url = `${BASE_URL}/${path}`
  const pageTitle = title + " | дekoder"
  const socialTitle = `${t("Infographic", lang.id)}: ${title}`
  const description = " "
  const type = "article"
  return (
    <Helmet>
      <html lang={lang.id} />
      <title>{pageTitle}</title>
      <meta name="description" content={description} />
      <meta name="abstract" content={description} />
      <link rel="image_src" href={image} />
      <link rel="canonical" href={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={socialTitle} />
      <meta property="og:url" content={url} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta name="twitter:title" content={socialTitle} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />
    </Helmet>
  )
}

export default Seo
