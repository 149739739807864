import { useState, useCallback } from "react"
import { getElectionUid } from "../../../elections-meta-data"

export default function useElections(pageContext, elections) {
  const { preselectedElId, preselectedDsId } = pageContext
  // const latestEl = elections[0]
  // const latestDs = latestEl.datasets[0]
  const presEl = preselectedElId
    ? elections.find((e) => getElectionUid(e) === preselectedElId)
    : null
  const presDs =
    preselectedDsId !== "undefined" && presEl?.datasets
      ? presEl.datasets.find((ds) => ds.id === preselectedDsId) ||
        presEl.datasets[0]
      : null
  // console.log("PRES El", presEl, presDs, "presDsId", preselectedDsId, pageContext)
  const [currElection, setcurrElection] = useState(presEl) // || latestEl)
  const [currDs, setcurrDs] = useState(presDs) // || latestDs)

  const onDsChange = useCallback(
    (datasetFilter, dsId) => {
      function getElectionByDatasetFilter(dsF) {
        return elections.find(
          (e) =>
            e.country === dsF.country &&
            e.type === dsF.type &&
            e.year === dsF.year
        )
      }
      function getDatasetById(dsId, election = currElection) {
        return election.datasets.find((ds) => ds.id === dsId)
      }
      function setElection(newElection, dsId) {
        // setLoaded(false)
        setcurrElection(newElection)
        setcurrDs(getDatasetById(dsId, newElection))
      }
      const newElection = getElectionByDatasetFilter(datasetFilter)
      if (!getDatasetById(dsId, newElection)) return
      setElection(newElection, dsId)
    },
    [currElection, elections]
  )

  return [currElection, currDs, onDsChange]
}
