import { useState, useCallback } from "react"

export default function usePointHover(currDs) {
  const [hoverTurnout, setHoverTurnout] = useState(0)
  const onPointMouseEnter = useCallback(
    (properties) => {
      const dsId = currDs.id
      const turnout = Math.round(properties[`turnout${dsId}`])
      setHoverTurnout(turnout)
    },
    [currDs]
  )

  const onPointMouseLeave = useCallback(() => {
    setHoverTurnout(0)
  }, [])
  return [hoverTurnout, [onPointMouseEnter, onPointMouseLeave]]
}
