import { useMemo } from "react"
import { GeoJsonLayer, IconLayer } from "deck.gl"
import { DataFilterExtension } from "@deck.gl/extensions"
import chroma from "chroma-js"
import {
  TOTAL_VOTES,
  TURNOUT,
  WINNER_GAP,
  WINNER_ID,
} from "../map-controller/geojson"

const ICON_MAPPING = {
  marker: { x: 0, y: 0, anchorY: 128, width: 128, height: 128, mask: true },
}

export function useLayers(
  geoJson,
  allCands,
  onClick,
  selectedFilter,
  dsId,
  colorBy,
  colorStops,
  selected
) {
  const layers = useMemo(() => {
    if (!allCands?.length) return []
    const filterRange = [selectedFilter.range.min, selectedFilter.range.max]
    return [
      new GeoJsonLayer({
        id: "stations",
        data: geoJson,
        // Styles
        filled: true,
        pointRadiusMinPixels: 2,
        pointRadiusMaxPixels: 20,
        pointRadiusScale: 60, // 500, // 60
        getPointRadius: (f) => {
          const votes = f.properties[`${TOTAL_VOTES}${dsId}`]
          const res = getInterpolate(votes, [
            [200, 4], // 100 votes -> 3.5
            [1200, 8], // 1600 votes -> 8
          ])
          return res
        },
        getFillColor: (f) => {
          const color =
            colorBy === "turnout"
              ? getRampColor(f.properties[`${TURNOUT}${dsId}`], colorStops)
              : getCandColor(f.properties[`${WINNER_ID}${dsId}`], allCands)
          const opacity = calcOpacity(f.properties[`${WINNER_GAP}${dsId}`]) || 1
          return color ? [...chroma(color).rgb(), opacity] : [200, 0, 80, 0] // fallback w/ opacity 0
        },
        stroked: false,
        pickable: true,
        autoHighlight: true,
        onClick,
        getFilterValue: (f) => {
          const res = f.properties[`${selectedFilter.name}${dsId}`]
          // console.log(res, filterRange)
          return res
        },
        filterRange: filterRange,
        extensions: [new DataFilterExtension({ filterSize: 1 })],
        updateTriggers: {
          getFilterValue: [selectedFilter.name, dsId],
          getFillColor:
            colorBy === "turnout"
              ? [colorBy, colorStops, dsId]
              : [colorBy, dsId],
          getPointRadius: [dsId],
        },
      }),
      new IconLayer({
        id: "icon-layer",
        data: selected ? [{ coordinates: [selected.lon, selected.lat] }] : [],
        getIcon: (d) => "marker",
        iconAtlas:
          "https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png",
        iconMapping: ICON_MAPPING,
        sizeScale: 10,
        getPosition: (d) => d.coordinates,
        getSize: (d) => 5,
        getColor: (d) => [200, 0, 80, 180],
      }),
    ]
  }, [
    geoJson,
    allCands,
    onClick,
    selectedFilter,
    dsId,
    colorBy,
    colorStops,
    selected,
  ])
  return layers
}

function calcOpacity(gap) {
  return gap >= 20 ? 255 : Math.round((gap / 20) * 255)
}

function getCandColor(candId, allCands) {
  const cand = allCands.find((c) => c.id === candId)
  return cand?.color
}

function getRampColor(val, colorStops) {
  const [[minVal, minCol], [medVal, medCol], [maxVal, maxCol]] = colorStops
  if (val <= minVal) return minCol
  if (val >= maxVal) return maxCol
  const lowerDist = medVal - minVal
  const higherDist = maxVal - medVal
  const lowerScale = chroma.scale([minCol, medCol])
  const higherScale = chroma.scale([medCol, maxCol])
  if (val <= medVal) return lowerScale((val - minVal) / lowerDist)
  else return higherScale((val - medVal) / higherDist)
}

function getInterpolate(value, stops) {
  const [[minVal, minOut], [maxVal, maxOut]] = stops
  if (value <= minVal) return minOut
  if (value >= maxVal) return maxOut
  const valDist = maxVal - minVal
  const outDist = maxOut - minOut
  const percent = value / valDist
  return minOut + percent * outDist
}
