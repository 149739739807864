import React from "react"
import "./loading-progress-bar.scss"

const LoadingProgressBar = ({ percent }) => {
  return (
    <div
      className={`loading-progress-bar-wrapper ${
        percent >= 100 ? "hidden" : ""
      }`}
    >
      <div
        className="loading-progress-bar"
        style={{ width: `${Math.round(percent)}%` }}
      />
    </div>
  )
}

export default LoadingProgressBar
