import { useMemo, useEffect } from "react"
import { langs } from "../../../meta-data-node"
import { getDsPath } from "../../../elections-meta-data"

export default function useLangs(langId, currElection, currDs) {
  const lang = useMemo(() => {
    return langId !== ""
      ? langs.find((l) => l.id === langId) || langs[1]
      : langs[1]
  }, [langId])

  useEffect(() => {
    window.localStorage.setItem("langId", lang.id)
    // updateUrl()
  }, [lang])

  const langsWithDestination = useMemo(
    () =>
      langs.map((l) => ({
        ...l,
        destination: `/${getDsPath(l.id, currElection, currDs)}`,
      })),
    [currElection, currDs]
  )

  return [lang, langsWithDestination]
}
