import { useMemo } from "react"

export default function useBounds(currEntity, geoJson) {
  return useMemo(() => {
    return currEntity.typeLong === "station" ? null : getBounds(geoJson)
  }, [geoJson, currEntity])
}

function getBounds(geoJson) {
  if (!geoJson) return
  const features = geoJson.features.filter(
    (f) => !f.properties.isForeignStation
  )
  const lonArray = features.map((f) => f.geometry.coordinates[0])
  const latArray = features.map((f) => f.geometry.coordinates[1])
  if (!lonArray.length || !latArray.length) return
  const minLon = arrayMin(lonArray)
  if (!minLon) return
  const minLat = arrayMin(latArray)
  const maxLon = arrayMax(lonArray)
  const maxLat = arrayMax(latArray)
  return [
    [minLon, minLat],
    [maxLon, maxLat],
  ]
}

function arrayMin(arr) {
  var len = arr.length,
    min = Infinity
  while (len--) {
    if (arr[len] < min) {
      min = arr[len]
    }
  }
  return min
}

function arrayMax(arr) {
  var len = arr.length,
    max = -Infinity
  while (len--) {
    if (arr[len] > max) {
      max = arr[len]
    }
  }
  return max
}
