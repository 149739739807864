import { transcribe } from "transkribator"

function t(string, targetLang, sourceLang = "ru") {
  const translations = {
    Turnout: {
      en: "Turnout",
      de: "Wahlbeteiligung",
      uk: "Явка",
      ru: "Явка",
    },
    Votes: {
      // total
      en: "Votes",
      de: "Stimmabgaben",
      uk: "Кількість голосів",
      ru: "К-во голосов",
    },
    votes: {
      // per candidate
      en: "Votes",
      de: "Stimmen",
      uk: "Кількість голосів",
      ru: "К-во голосов",
    },
    Region: {
      en: "Oblast",
      de: "Oblast",
      uk: "Область",
      ru: "Область",
    },
    "Electoral district": {
      en: "Electoral district",
      de: "Wahlbezirk",
      uk: "Територіальний виборчий округ",
      ru: "Территориальный избирательный округ",
    },
    "Polling station": {
      en: "Polling station",
      de: "Wahllokal",
      uk: "Виборча дільниця",
      ru: "Избирательный участок",
    },
    "click to filter": {
      de: "Click to filter (Alt + click for multiple selection)",
      en: "Click to filter (Alt + click for multiple selection)",
      uk: "Click to filter (Alt + click for multiple selection)",
      ru: "Click to filter (Alt + click for multiple selection)",
    },
    "click to unfilter": {
      de: "klicken, um Filter zu entfernen",
      en: "click to unfilter",
      uk: "click to unfilter",
      ru: "click to unfilter",
    },
    "show all": {
      // candidates
      de: "alle anzeigen",
      en: "show all",
      uk: "показати всіх",
      ru: "показать всех",
    },
    "show less": {
      de: "weniger anzeigen",
      en: "show less",
      uk: "не показати всіх",
      ru: "не показать всех",
    },
    "show overall result": {
      de: "zurück zum Gesamtergebnis",
      en: "show overall result",
      uk: "показати загальний результат",
      ru: "показать общий результат",
    },
    "Overall result": {
      de: "Gesamtergebnis",
      en: "Overall result",
      uk: "Загальний результат",
      ru: "Общий результат",
    },
    "Закордонний виборчий округ": {
      de: "Ausland",
      en: "Foreign electoral district",
      uk: "Закордонний виборчий округ",
      ru: "Заграничный избирательный округ",
    },
    Infographic: {
      de: "Infografik",
      en: "Infographic",
      uk: "Інфографіка",
      ru: "Инфографика",
    },
    "The results are updated regularly": {
      de: "Die Ergebnisse werden regelmäßig aktualisiert",
      en: "The results are updated regularly",
      uk: "Результати регулярно оновлюються",
      ru: "Результаты регулярно обновляются",
    },
    "Last update": {
      de: "Stand",
      en: "Last update",
      ru: "Обновлено",
      uk: "Оновлене",
    },
    Source: {
      de: "Quelle",
      en: "Source",
      ru: "Источник",
      uk: "Джерело",
    },
    "Counting status": {
      de: "Auszählungsstand",
      en: "Counting status",
      ru: "Обработано протоколов",
      uk: "Оброблено протоколів",
    },
    "Read also": {
      de: "Lesen Sie auch",
      en: "Read also",
      uk: "Читайте також",
      ru: "Читайте также",
    },
    Donate: {
      de: "Spenden",
      en: "Donate",
      uk: "Пожертвувати",
      ru: "Пожертвовать",
    },
    "1st round": {
      de: "Erster Wahlgang",
      en: "First round",
      uk: "Перший тур",
      ru: "Первый тур",
    },
    "2nd round": {
      de: "Stichwahl",
      en: "Second round",
      uk: "Другий тур",
      ru: "Второй тур",
    },
    ukraine: {
      de: "Ukraine",
      en: "Ukraine",
      uk: "Україна",
      ru: "Украина",
    },
    presidential: {
      de: "Präsidentschaftswahlen",
      en: "Presidential Elections",
      uk: "Вибори президента",
      ru: "Президентские выборы",
    },
    "Winner-Gap": {
      de: "Abstand zwischen 1. und 2. Platz",
      en: "Gap between 1st and 2nd",
      uk: "Різниця між першим і другим",
      ru: "Разница между первым и вторым ",
    },
    Filter: {
      de: "Filter",
      en: "Filter",
      uk: "Фільтр",
      ru: "Фильтр ",
    },
    parliamentary: {
      de: "Parlamentswahlen",
      en: "Parliamentary Elections",
      uk: "Парламентські вибори",
      ru: "Парламентские выборы",
    },
    "multi-mandate": {
      de: "Parteistimmen",
      en: "multi-mandate",
      uk: "Багатомандатний виборчий округ",
      ru: "Многомандатный избирательный округ",
    },
    "single-mandate": {
      de: "Direktmandate",
      en: "single-mandate",
      uk: "Одномандатний виборчий округ",
      ru: "Одномандатный избирательный округ",
    },
    russia: {
      de: "Russland",
      en: "Russia",
      uk: "Росія",
      ru: "Россия",
    },
    constitution: {
      de: "Verfassungsänderung",
      en: "Constitutional Referendum",
      ru: "Поправки к Конституции",
      uk: "Поправки до Конституції",
    },
    histogram: {
      de: "Histogramm",
      en: "histogram",
      ru: "гистограмма",
      uk: "гістограма",
    },
    source: {
      de: "Quelle",
      en: "Source",
      ru: "Источник",
      uk: "Джерело",
    },
    Да: {
      de: "ja",
      en: "yes",
      ru: "да",
      uk: "так",
    },
    Нет: {
      de: "nein",
      en: "no",
      ru: "нет",
      uk: "ні",
    },
    "Российская Федерация": {
      de: "Russische Föderation",
      en: "Russian Federation",
      ru: "Российская Федерация",
      uk: "Російська Федерація",
    },
    "party-list": {
      de: "Listenwahl",
      en: "party-list",
      ru: "по партийным спискам",
      uk: "за партійними списками",
    },
    "all votes": {
      de: "alle",
      en: "all",
      ru: "все",
      uk: "всі",
    },
    "only electronic vote": {
      de: "nur online",
      en: "only electronic vote",
      ru: "только электронные",
      uk: "только электронные",
    },
    "no electronic vote": {
      de: "nur Wahllokal",
      en: "no electronic vote",
      ru: "только очные",
      uk: "только очные",
    },
    Results: {
      de: "Ergebnisse",
      en: "Results",
      ru: "результаты",
      uk: "результати",
    },
    "All Elections": {
      de: "Alle Wahlen",
      en: "All Elections",
      ru: "Все выборы",
      uk: "Всі вибори",
    },
    Countries: {
      de: "Länder",
      en: "countries",
      ru: "страны",
      uk: "країни",
    },
  }
  if (translations[string]?.[targetLang]) {
    return translations[string][targetLang]
  } else if (["de", "en"].includes(targetLang) && sourceLang !== targetLang)
    return transcribe(string, { to: targetLang, from: sourceLang })
  else return string
}

export default t
