import React, { useContext } from "react"
import { ResponsiveLine } from "@nivo/line"
import t from "../../modules/translations"
import "./histogram.scss"
import LangContext from "../../contexts/lang"

const markerCommonProps = {
  axis: "x",
  lineStyle: {
    stroke: "hsl(0,0%,80%)", // "gray"
  },
  textStyle: {
    fill: "gray",
    letterSpacing: ".5px",
    textTransform: "uppercase",
    fontSize: "0.64em",
    fontFamily: "Roboto Condensed",
  },
  legendOrientation: "vertical",
}

const Histogram = ({ data = [], turnout }) => {
  const lang = useContext(LangContext)
  const markers = turnout
    ? [
        {
          ...markerCommonProps,
          value: turnout,
          legend: `ø ${t("Turnout", lang.id)}`,
        },
      ]
    : []
  return (
    <>
      <div className={`histogram ${!data.length ? " hidden" : ""}`}>
        <ResponsiveLine
          data={data}
          margin={{ top: 10, right: 20, bottom: 30, left: 20 }}
          // enableGridX={false}
          // enableGridY={false}
          gridXValues={[
            0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80,
            85, 90, 95, 100,
          ]}
          curve={"linear"} // monotoneX
          enableArea={true}
          colors={{ datum: "color" }}
          enablePoints={false}
          // enableSlices={"x"}
          useMesh={true}
          xScale={{
            type: "linear",
            min: 0,
            max: 100,
          }}
          axisBottom={{
            orient: "bottom",
            tickSize: 7, // 3
            tickPadding: 5, // 3
            tickRotation: 0,
            // legend: turnoutStr,
            // legendOffset: 33,
            legendPosition: "middle",
            format: (v) => `${v} %`,
          }}
          axisLeft={null}
          markers={markers}
          theme={{
            axis: {
              fontFamily: "Roboto Condensed",
              legend: {
                text: {
                  fontFamily: "Roboto Condensed",
                  fontSize: "0.64em",
                  fill: "#666666", //  hsl(0%, 0%, 40%)"
                },
              },
              ticks: {
                line: {
                  stroke: "rgba(150, 150, 150, 0.5)",
                },
                text: {
                  fontFamily: "Roboto Condensed",
                  fontSize: "0.64em",
                  fill: "#666666", //  hsl(0%, 0%, 40%)"
                },
              },
            },
            grid: {
              line: {
                stroke: "var(--stroke-color)",
                strokeDasharray: "4 4",
              },
            },
          }}
        />
      </div>
    </>
  )
}

export default React.memo(Histogram)

/**
 * axisRight={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "votes",
          legendOffset: 30,
          legendPosition: "middle",
        }}
 */
