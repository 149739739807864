import React, { useContext } from "react"
import t from "../../modules/translations"
import "./result-preview.scss"
import LangContext from "../../contexts/lang"

const ResultPreview = ({ currDs, currEntity }) => {
  const lang = useContext(LangContext)
  const dsId = currDs.id
  const s = currEntity
  if (!s.results || !s.getResult(dsId)) return null
  const result = s.getResult(dsId)
  const totalShare = result.sortedCandidates.reduce(
    (acc, curr) => acc + curr.share,
    0
  )
  return (
    <div className="result-preview">
      <div className="result-preview-bar">
        {result.sortedCandidates.map((c, i) => {
          const width = Math.round((c.share / totalShare) * 100)
          return (
            <div
              key={i}
              className="result-preview-bar-el"
              style={{ width: `${width}%`, backgroundColor: c.candidate.color }}
            >
              {width >= 10 && <span>{t(c.candidate.name, lang.id)}</span>}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ResultPreview
