import styled from "@emotion/styled"

export const WidgetWrapper = styled.div``

export const IconWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 0.64em;
  line-height: 1.5em;
`
