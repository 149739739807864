import React, { useEffect, useMemo } from "react"
import Seo from "../seo"
import Header from "../header"
import MapController from "../map-controller"
import FilterSelector from "../filter-selector"
import InfoPanel from "../info-panel"
import SiteLogos from "../site-logos"
import LangContext from "../../contexts/lang"
import MenuButton from "../menu-button"
import LangSwitcher from "../lang-switcher"
import LoadingProgressBar from "../loading-progress-bar"
import "./app.scss"
import { baseUrl, siteLogos } from "../../../meta-data-es6"
import { elections } from "../../../elections-meta-data"
import useLangs from "./langs"
import useFilters from "../filter-selector/filters"
import usePointHover from "./point-hover"
import useStatistics from "./statistics"
import useElections from "./elections"
import useElLoader from "./election-loader"
import useInfoPanel from "./info-panel"
import usePath from "./path"
import { useCustomFilter } from "./custom-filter"

const App = ({ pageContext, location }) => {
  const s = location.search
  const electionProps = useElections(pageContext, elections)
  const [currElection, currDs] = electionProps
  const [entites, l, elData, interactions] = useElLoader(
    currElection,
    s,
    baseUrl
  )
  const [currEntityUnfiltered, highestEntity] = entites

  const allCands = useMemo(() => {
    if (!highestEntity || !currDs) return []
    return highestEntity
      .getResult(currDs.id)
      .candidates.map((cr) => cr.candidate)
    // return highestEntity.results[currDs.id].candidates.map(cr => cr.candidate)
  }, [highestEntity, currDs])

  const [loaded, totalProgress] = l
  const [lang, langs] = useLangs(pageContext.langId, currElection, currDs)

  const [currEntity, customFilterComp, updTrigger] = useCustomFilter(
    currEntityUnfiltered,
    currElection,
    lang,
    currDs?.id
  )
  useEffect(() => {
    window.currEntity = currEntity
  }, [currEntity])

  const [path] = usePath(lang.id, currElection, currDs, currEntity)
  const [infoPanelOpen, setInfoPanelOpen] = useInfoPanel(currEntity)
  const [selectedFilter, onFilterChange] = useFilters()
  const [hoverTurnout, hoverInteractions] = usePointHover(currDs)
  const statistics = useStatistics(
    loaded,
    currEntity,
    currDs,
    highestEntity,
    updTrigger
  )
  const { socialImages, socialImage } = currDs || {}
  const title = currElection ? currElection.title[lang.id] : "Election Viz"
  const className = `election-viz 
    ${infoPanelOpen ? "info-panel-open" : "info-panel-closed"} 
    ${currElection && !loaded ? "spinner" : ""}
    ${loaded ? "loaded" : "not-loaded"}`

  return (
    <LangContext.Provider value={lang}>
      <LoadingProgressBar percent={totalProgress * 100} />
      <Seo
        title={title}
        socialImage={socialImages || socialImage}
        path={path}
      />
      <Header>
        <SiteLogos logos={siteLogos} />
        <LangSwitcher allLangs={langs} />
        <MenuButton
          menuOpen={infoPanelOpen}
          onClick={() => setInfoPanelOpen((o) => !o)}
        />
      </Header>
      <div className={className}>
        <FilterSelector
          onFilterChange={onFilterChange}
          selectedFilter={selectedFilter}
        />
        <MapController
          electionProps={electionProps}
          electionData={elData}
          currEntity={currEntity}
          selectedFilter={selectedFilter}
          colorStops={statistics[0]}
          onPointClick={interactions[1]}
          hoverInteractions={hoverInteractions}
          allCands={allCands}
        />
        <InfoPanel
          electionProps={electionProps}
          electionData={elData}
          currEntity={currEntity}
          interactions={interactions}
          statistics={statistics}
          hoverTurnout={hoverTurnout}
          customFilterComp={customFilterComp}
          allCands={allCands}
          updTrigger={updTrigger}
          collapsed={!infoPanelOpen}
          setInfoPanelOpen={setInfoPanelOpen}
        />
      </div>
    </LangContext.Provider>
  )
}

export default App
