import { useState, useCallback } from "react"

const defaultFilter = {
  name: "",
  range: { min: 0, max: 100 },
}

function useFilters() {
  const [selectedFilter, setSelectedFilter] = useState(defaultFilter)

  const onFilterChange = useCallback((name, range) => {
    setSelectedFilter({ name, range })
  }, [])

  return [selectedFilter, onFilterChange]
}

export default useFilters
